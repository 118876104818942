// src/utils/auth.ts
interface AuthResponse {
    access_token: string;
    refresh_token: string;
    user: any;
  }
  
  // Функция сохранения токенов
  export const saveAuthTokens = (authData: AuthResponse) => {
    const accessTokenExpiry = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
    const refreshTokenExpiry = new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000);
  
    document.cookie = `access_token=${authData.access_token}; expires=${accessTokenExpiry.toUTCString()}; path=/`;
    document.cookie = `refresh_token=${authData.refresh_token}; expires=${refreshTokenExpiry.toUTCString()}; path=/`;
    document.cookie = `user_data=${JSON.stringify(authData.user)}; expires=${refreshTokenExpiry.toUTCString()}; path=/`;
  };
  
  // Получение значения куки
  export const getCookie = (name: string): string | null => {
    const matches = document.cookie.match(new RegExp(
      "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
  };
  
  // Проверка актуальности токена
  export const checkAndRefreshToken = async (supabase: any) => {
    const refreshToken = getCookie('refresh_token');
    
    if (refreshToken) {
      try {
        const { data, error } = await supabase.auth.refreshSession({
          refresh_token: refreshToken
        });
  
        if (error) throw error;
  
        if (data && data.session) {
          const { access_token, refresh_token } = data.session;
          const { user } = data;
          saveAuthTokens({ access_token, refresh_token, user });
          return { success: true, session: data.session };
        }
      } catch (error) {
        console.error('Ошибка обновления токена:', error);
        return { success: false, error };
      }
    }
    return { success: false, error: 'Отсутствует refresh token' };
  };