import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './ProfilePage.scss';
import { useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { checkAndRefreshToken, getCookie } from '../utils/auth';

interface AnimeStats {
  daily: number;
  weekly: number;
  monthly: number;
}

interface FavoriteAnime {
  id: string;
  title: string;
  poster: string;
  genres?: Array<{
    id: string;
    name: string;
    russian: string;
  }>;
}

interface UserProfile {
  username: string;
  stats: AnimeStats;
  favorites: FavoriteAnime[];
  watching: FavoriteAnime[];
  planned: FavoriteAnime[];
  dropped: FavoriteAnime[];
  completed: FavoriteAnime[];
}

const ProfilePage: React.FC = () => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState<{[key: string]: boolean}>({});
  const [animationPlayed, setAnimationPlayed] = useState<{[key: string]: boolean}>({});
  const navigate = useNavigate();
  const sectionRefs = useRef<{[key: string]: React.RefObject<HTMLElement | null>}>({
    favorites: React.createRef(),
    watching: React.createRef(),
    completed: React.createRef(),
    dropped: React.createRef(),
    planned: React.createRef(),
  });

  const toggleSection = (section: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [section]: !prev[section]
    }));
  };

  // Проверка на ошибки при загрузке постеров
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = 'https://via.placeholder.com/200x300?text=Нет+изображения';
  };

  useEffect(() => {
    // Intersection Observer для анимаций при скролле
    const observers: IntersectionObserver[] = [];
    
    Object.keys(sectionRefs.current).forEach(section => {
      const sectionRef = sectionRefs.current[section];
      if (sectionRef.current) {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting && !animationPlayed[section]) {
                setAnimationPlayed(prev => ({ ...prev, [section]: true }));
              }
            });
          },
          { threshold: 0.1 }
        );
        
        observer.observe(sectionRef.current);
        observers.push(observer);
      }
    });
    
    return () => {
      observers.forEach(observer => observer.disconnect());
    };
  }, [profile, animationPlayed]);

  const AnimeCard: React.FC<{ 
    anime: FavoriteAnime; 
    onClick: (id: string) => void;
    index: number;
    isAnimated: boolean;
    sectionKey: string;
  }> = ({ anime, onClick, index, isAnimated, sectionKey }) => {
    const getRandomDelay = () => {
      const baseDelay = 0.1;
      return baseDelay + (index % 10) * 0.05;
    };
    
    return (
      <div 
        className={`anime-card ${isAnimated ? 'animate' : ''}`}
        style={{ 
          animationDelay: `${getRandomDelay()}s`,
          opacity: isAnimated ? 1 : 0,
          transform: isAnimated ? 'translateY(0)' : 'translateY(20px)'
        }}
        onClick={() => onClick(anime.id)}
        data-section={sectionKey}
        data-index={index}
      >
        <div className="anime-poster">
          <img 
            src={anime.poster} 
            alt={anime.title} 
            loading="lazy" 
            onError={handleImageError}
          />
          <div className="anime-overlay">
            <div className="watch-button">
              <i className="fi fi-rr-play"></i>
            </div>
          </div>
        </div>
        <div className="anime-info">
          <h3 className="anime-title">{anime.title}</h3>
          {anime.genres && (
            <div className="anime-genres">
              {anime.genres.slice(0, 2).map(genre => (
                <span key={genre.id} className="genre-tag">{genre.russian}</span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    document.title = "Профиль пользователя - AniNew";
    
    const fetchProfile = async () => {
      try {
        setLoading(true);
        // Создаем клиент Supabase
        const supabaseUrl = 'https://btotmorcpopjxetahrdv.supabase.co';
        const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
        if (!supabaseKey) {
          throw new Error('REACT_APP_SUPABASE_KEY is not defined');
        }
        const supabase = createClient(supabaseUrl, supabaseKey);
  
        // Проверяем и обновляем токен
        await checkAndRefreshToken(supabase);
  
        // Получаем обновленный токен
        const access_token = getCookie('access_token');
        const response = await axios.get('https://aninew.ru/api/me', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        
        // Инициализируем пустые массивы и статистику, если данных нет
        const defaultProfile = {
          ...response.data,
          stats: response.data?.stats || { daily: 0, weekly: 0, monthly: 0 },
          favorites: response.data?.favorites || [],
          watching: response.data?.watching || [],
          planned: response.data?.planned || [],
          dropped: response.data?.dropped || [],
          completed: response.data?.completed || []
        };
        
        setProfile(defaultProfile);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setLoading(false);
      }
    };
    
    fetchProfile();
  }, []);

  const renderEmptyState = (title: string) => (
    <div className="empty-state">
      <div className="empty-icon">
        <i className="fi fi-rr-sad"></i>
      </div>
      <h3>Список пуст</h3>
      <p>В вашем списке {title.toLowerCase()} пока нет аниме</p>
      <button className="browse-button" onClick={() => navigate('/catalog')}>
        Найти аниме
      </button>
    </div>
  );

  const renderAnimeGrid = (animeList: FavoriteAnime[], sectionKey: string) => {
    const isExpanded = expandedSections[sectionKey] || false;
    const displayedAnime = isExpanded ? animeList : animeList.slice(0, 6);
    const isAnimated = animationPlayed[sectionKey] || false;
    
    if (!animeList || animeList.length === 0) {
      return renderEmptyState(sectionKey);
    }
    
    return (
      <>
        <div className={`anime-grid ${isExpanded ? 'expanded' : ''}`}>
          {displayedAnime.map((anime, index) => (
            <AnimeCard 
              key={anime.id}
              anime={anime}
              onClick={() => navigate(`/anime/${anime.id}`)}
              index={index}
              isAnimated={isAnimated}
              sectionKey={sectionKey}
            />
          ))}
        </div>
        
        {animeList.length > 6 && (
          <button 
            className="show-more-button"
            onClick={() => toggleSection(sectionKey)}
          >
            <span className="button-text">
              {isExpanded ? 'Свернуть' : `Показать все (${animeList.length})`}
            </span>
            <span className={`arrow ${isExpanded ? 'up' : 'down'}`}>
              <i className={`fi ${isExpanded ? 'fi-rr-angle-up' : 'fi-rr-angle-down'}`}></i>
            </span>
          </button>
        )}
      </>
    );
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-animation">
          <div className="spinner"></div>
          <div className="spinner-ring"></div>
        </div>
        <div className="loading-text">Загрузка профиля...</div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="error-screen">
        <div className="error-icon">
          <i className="fi fi-rr-user-slash"></i>
        </div>
        <h2>Профиль не найден</h2>
        <p>Возможно, вы не авторизованы или произошла ошибка</p>
        <button className="return-button" onClick={() => navigate('/login')}>
          Войти в аккаунт
        </button>
      </div>
    );
  }

  return (
    <div className="profile-page">
      <div className="content-wrapper">
        <div className="profile-header">
          <div className="user-info">
            <div className="avatar">
              <span>{profile.username.charAt(0).toUpperCase()}</span>
            </div>
            <div className="username">
              <h1>{profile.username}</h1>
              <span className="member-since">Участник AniNew</span>
            </div>
          </div>
          <div className="stats-wrapper">
            <h2 className="stats-title">Статистика просмотров</h2>
            <div className="stats">
              <div className="stat-item">
                <div className="stat-value">{profile.stats?.daily || 0}</div>
                <div className="stat-label">За день</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">{profile.stats?.weekly || 0}</div>
                <div className="stat-label">За неделю</div>
              </div>
              <div className="stat-item">
                <div className="stat-value">{profile.stats?.monthly || 0}</div>
                <div className="stat-label">За месяц</div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="profile-summary">
          <div className="summary-box">
            <div className="summary-icon"><i className="fi fi-rr-heart"></i></div>
            <div className="summary-value">{profile.favorites?.length || 0}</div>
            <div className="summary-label">В избранном</div>
          </div>
          <div className="summary-box">
            <div className="summary-icon"><i className="fi fi-rr-play"></i></div>
            <div className="summary-value">{profile.watching?.length || 0}</div>
            <div className="summary-label">Смотрю</div>
          </div>
          <div className="summary-box">
            <div className="summary-icon"><i className="fi fi-rr-check"></i></div>
            <div className="summary-value">{profile.completed?.length || 0}</div>
            <div className="summary-label">Завершено</div>
          </div>
          <div className="summary-box">
            <div className="summary-icon"><i className="fi fi-rr-bookmark"></i></div>
            <div className="summary-value">{profile.planned?.length || 0}</div>
            <div className="summary-label">В планах</div>
          </div>
        </div>
        
        <div className="anime-lists">
          <section 
            className={`section-favorites ${animationPlayed.favorites ? 'animate' : ''}`}
            ref={sectionRefs.current.favorites as React.RefObject<HTMLElement>}
          >
            <h2 className="section-title">
              <i className="fi fi-rr-heart"></i>
              <span>Избранное</span>
            </h2>
            {renderAnimeGrid(profile.favorites, 'favorites')}
          </section>
          
          <section 
            className={`section-watching ${animationPlayed.watching ? 'animate' : ''}`}
            ref={sectionRefs.current.watching as React.RefObject<HTMLElement>}
          >
            <h2 className="section-title">
              <i className="fi fi-rr-play"></i>
              <span>Смотрю</span>
            </h2>
            {renderAnimeGrid(profile.watching, 'watching')}
          </section>
          
          <section 
            className={`section-completed ${animationPlayed.completed ? 'animate' : ''}`}
            ref={sectionRefs.current.completed as React.RefObject<HTMLElement>}
          >
            <h2 className="section-title">
              <i className="fi fi-rr-check"></i>
              <span>Завершено</span>
            </h2>
            {renderAnimeGrid(profile.completed, 'completed')}
          </section>
          
          <section 
            className={`section-dropped ${animationPlayed.dropped ? 'animate' : ''}`}
            ref={sectionRefs.current.dropped as React.RefObject<HTMLElement>}
          >
            <h2 className="section-title">
              <i className="fi fi-rr-cross-circle"></i>
              <span>Брошено</span>
            </h2>
            {renderAnimeGrid(profile.dropped, 'dropped')}
          </section>
          
          <section 
            className={`section-planned ${animationPlayed.planned ? 'animate' : ''}`}
            ref={sectionRefs.current.planned as React.RefObject<HTMLElement>}
          >
            <h2 className="section-title">
              <i className="fi fi-rr-bookmark"></i>
              <span>Запланировано</span>
            </h2>
            {renderAnimeGrid(profile.planned, 'planned')}
          </section>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;