import React, { useState, useCallback, useEffect } from 'react';
import './EpisodeDownloader.scss';

interface DownloaderProps {
  videoLinks: {
    direct: Array<{ url: string; quality: number }>;
    kodik: string[];
  } | null;
  currentEpisode: number | null;
  animeName: string;
}

const EpisodeDownloader: React.FC<DownloaderProps> = ({ 
  videoLinks, 
  currentEpisode, 
  animeName 
}) => {
  const MIN_DISPLAY_SPEED = 10 * 1024; // Минимум 10 КБ/с
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadSpeed, setDownloadSpeed] = useState(MIN_DISPLAY_SPEED);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [selectedQuality, setSelectedQuality] = useState<string | null>(null);
  const [showQualitySelector, setShowQualitySelector] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalSegments, setTotalSegments] = useState(0);
  const [downloadedSegments, setDownloadedSegments] = useState(0);
  const [totalBytes, setTotalBytes] = useState(0);
  const [downloadedBytes, setDownloadedBytes] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [abortController, setAbortController] = useState<AbortController | null>(null);
  const [concurrency, setConcurrency] = useState(5);

  useEffect(() => {
    const savedConcurrency = localStorage.getItem('downloadConcurrency');
    if (savedConcurrency) {
      setConcurrency(parseInt(savedConcurrency, 10));
    }
  }, []);

  const updateConcurrency = (value: number) => {
    const newValue = Math.max(1, Math.min(value, 50));
    setConcurrency(newValue);
    localStorage.setItem('downloadConcurrency', newValue.toString());
  };

  const availableQualities = React.useMemo(() => {
    if (!videoLinks) return [];
    
    const qualities: {label: string, value: string}[] = [];

    videoLinks.kodik.forEach(link => {
      const qualityMatch = link.match(/\/(\d+)\.mp4/);
      if (qualityMatch && qualityMatch[1]) {
        const quality = qualityMatch[1];
        qualities.push({
          label: `${quality}p`,
          value: link
        });
      }
    });

    videoLinks.direct.forEach(link => {
      qualities.push({
        label: `${link.quality}p`,
        value: link.url
      });
    });

    return qualities.sort((a, b) => {
      const qualityA = parseInt(a.label);
      const qualityB = parseInt(b.label);
      return qualityB - qualityA;
    });
  }, [videoLinks]);

  const handleDownloadClick = () => {
    if (!videoLinks || availableQualities.length === 0) {
      setError('Нет доступных ссылок для скачивания');
      return;
    }

    setShowQualitySelector(true);
  };

  const cancelDownload = useCallback(() => {
    if (abortController) {
      abortController.abort();
      setAbortController(null);
    }
    setIsDownloading(false);
    setDownloadProgress(0);
    setDownloadSpeed(MIN_DISPLAY_SPEED);
    setEstimatedTime(0);
    setDownloadedSegments(0);
    setTotalSegments(0);
    setDownloadedBytes(0);
    setTotalBytes(0);
  }, [abortController]);

  useEffect(() => {
    return () => {
      if (abortController) {
        abortController.abort();
      }
    };
  }, [abortController]);

  const startDownload = async (qualityUrl: string) => {
    try {
      setIsDownloading(true);
      setError(null);
      setSelectedQuality(qualityUrl);
      setShowQualitySelector(false);
      setDownloadProgress(0);
      setDownloadedSegments(0);
      setTotalSegments(0);
      setDownloadedBytes(0);
      setTotalBytes(0);
      setDownloadSpeed(MIN_DISPLAY_SPEED);
      setStartTime(Date.now());

      const controller = new AbortController();
      setAbortController(controller);

      const safeAnimeName = animeName.replace(/[^\w\s]/gi, '').trim();
      const filename = `${safeAnimeName} - Эпизод ${currentEpisode}.mp4`;

      if (qualityUrl.includes('.m3u8')) {
        await downloadM3u8Video(qualityUrl, filename, controller.signal);
      } else {
        await downloadDirectVideo(qualityUrl, filename, controller.signal);
      }

      setIsDownloading(false);
      setDownloadProgress(100);
    } catch (err: any) {
      if (err.name === 'AbortError') {
        setError('Загрузка была отменена');
      } else {
        setError(`Ошибка загрузки: ${err.message}`);
      }
      setIsDownloading(false);
    }
  };

  const downloadDirectVideo = async (url: string, filename: string, signal: AbortSignal) => {
    try {
      const headResponse = await fetch(url, { method: 'HEAD' });
      const contentLength = parseInt(headResponse.headers.get('content-length') || '0');

      if (contentLength === 0) {
        throw new Error('Не удалось определить размер файла');
      }

      setTotalBytes(contentLength);

      const estimatedSeconds = contentLength / MIN_DISPLAY_SPEED;
      setEstimatedTime(Math.min(estimatedSeconds, 86400));

      const response = await fetch(url, { signal });

      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }

      const reader = response.body?.getReader();

      if (!reader) {
        throw new Error('Не удалось получить поток чтения');
      }

      const chunks: Uint8Array[] = [];
      let receivedBytes = 0;
      let lastUpdateTime = Date.now();
      let lastUpdateBytes = 0;

      while (true) {
        const { done, value } = await reader.read();

        if (done) {
          break;
        }

        chunks.push(value);
        receivedBytes += value.length;

        setDownloadedBytes(receivedBytes);
        setDownloadProgress(Math.floor((receivedBytes / contentLength) * 100));

        const currentTime = Date.now();
        if (currentTime - lastUpdateTime >= 500) {
          const intervalSeconds = (currentTime - lastUpdateTime) / 1000;
          const bytesInInterval = receivedBytes - lastUpdateBytes;

          if (intervalSeconds > 0 && bytesInInterval > 0) {
            const instantSpeed = bytesInInterval / intervalSeconds;
            const smoothedSpeed = downloadSpeed * 0.8 + instantSpeed * 0.2;
            const displaySpeed = Math.max(smoothedSpeed, MIN_DISPLAY_SPEED);
            setDownloadSpeed(displaySpeed);

            const remainingBytes = contentLength - receivedBytes;
            const estimatedSeconds = remainingBytes / displaySpeed;
            setEstimatedTime(Math.min(estimatedSeconds, 86400));

            lastUpdateTime = currentTime;
            lastUpdateBytes = receivedBytes;
          }
        }
      }

      const blob = new Blob(chunks, { type: 'video/mp4' });
      downloadBlob(blob, filename);
    } catch (err) {
      throw err;
    }
  };

  const downloadM3u8Video = async (m3u8Url: string, filename: string, signal: AbortSignal) => {
    try {
      const response = await fetch(m3u8Url, { signal });
      const m3u8Content = await response.text();

      const segmentUrls = parseM3u8ForSegments(m3u8Content, m3u8Url);

      if (segmentUrls.length === 0) {
        throw new Error('Не найдено видео сегментов в файле m3u8');
      }

      setTotalSegments(segmentUrls.length);

      const initialEstimatePerSegment = 200 * 1024; // 200 KB
      const initialEstimatedTotal = initialEstimatePerSegment * segmentUrls.length;

      setTotalBytes(initialEstimatedTotal);

      const initialEstimatedSeconds = initialEstimatedTotal / MIN_DISPLAY_SPEED;
      setEstimatedTime(Math.min(initialEstimatedSeconds, 86400));

      const concurrencyLimit = concurrency;
      const segmentBlobs: Blob[] = [];
      let downloadedSize = 0;
      let completedSegments = 0;
      let lastUpdateTime = Date.now();
      let lastUpdateBytes = 0;
      let lastUpdateSegments = 0;

      const queue = [...segmentUrls];

      const downloadSegment = async (url: string): Promise<Blob> => {
        const segResponse = await fetch(url, { signal });

        if (!segResponse.ok) {
          throw new Error(`Ошибка загрузки сегмента (${segResponse.status}): ${url}`);
        }

        const blob = await segResponse.blob();
        downloadedSize += blob.size;
        completedSegments++;

        setDownloadedBytes(downloadedSize);
        setDownloadedSegments(completedSegments);

        const progressPercent = Math.floor((completedSegments / segmentUrls.length) * 100);
        setDownloadProgress(progressPercent);

        const currentTime = Date.now();
        const segmentDelta = completedSegments - lastUpdateSegments;

        if (currentTime - lastUpdateTime >= 500 || segmentDelta >= 3) {
          const avgSegmentSize = downloadedSize / completedSegments;
          const refinedTotalSize = Math.round(avgSegmentSize * segmentUrls.length);
          setTotalBytes(refinedTotalSize);

          const intervalSeconds = (currentTime - lastUpdateTime) / 1000;
          const bytesInInterval = downloadedSize - lastUpdateBytes;

          if (intervalSeconds > 0 && bytesInInterval > 0) {
            const instantSpeed = bytesInInterval / intervalSeconds;
            const smoothedSpeed = downloadSpeed * 0.7 + instantSpeed * 0.3;
            const displaySpeed = Math.max(smoothedSpeed, MIN_DISPLAY_SPEED);
            setDownloadSpeed(displaySpeed);

            const remainingSegments = segmentUrls.length - completedSegments;
            const remainingSize = remainingSegments * avgSegmentSize;
            const estimatedSeconds = remainingSize / displaySpeed;
            setEstimatedTime(Math.min(estimatedSeconds, 86400));

            lastUpdateTime = currentTime;
            lastUpdateBytes = downloadedSize;
            lastUpdateSegments = completedSegments;
          }
        }

        return blob;
      };

      while (queue.length > 0) {
        const batchSize = Math.min(concurrencyLimit, queue.length);
        const batch = queue.splice(0, batchSize);

        const batchResults = await Promise.all(
          batch.map(url => downloadSegment(url).catch(error => {
            console.error(`Ошибка загрузки сегмента ${url}:`, error);
            return null;
          }))
        );

        segmentBlobs.push(...batchResults.filter(Boolean) as Blob[]);

        if (signal.aborted) {
          throw new Error('Загрузка была отменена');
        }
      }

      setTotalBytes(downloadedSize);

      const videoBlob = new Blob(segmentBlobs, { type: 'video/mp4' });
      downloadBlob(videoBlob, filename);

    } catch (err) {
      throw err;
    }
  };

  const parseM3u8ForSegments = (content: string, baseUrl: string): string[] => {
    const lines = content.split('\n');
    const segmentUrls: string[] = [];
    const basePath = baseUrl.substring(0, baseUrl.lastIndexOf('/') + 1);
    const baseUrlObj = new URL(baseUrl);

    if (content.includes('#EXT-X-STREAM-INF')) {
      throw new Error('Обнаружен мастер-плейлист. Пожалуйста, выберите конкретное качество.');
    }

    let isSegmentUrl = false;

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i].trim();

      if (line === '' || line.startsWith('#')) {
        if (line.startsWith('#EXTINF')) {
          isSegmentUrl = true;
        }
        continue;
      }

      if (isSegmentUrl) {
        let segmentUrl: string;

        if (line.startsWith('http://') || line.startsWith('https://')) {
          segmentUrl = line;
        } else if (line.startsWith('/')) {
            segmentUrl = `${baseUrlObj.protocol}//${baseUrlObj.host}${line}`;
          } else {
            segmentUrl = `${basePath}${line}`;
          }
  
          segmentUrls.push(segmentUrl);
          isSegmentUrl = false;
        }
      }
  
      return segmentUrls;
    };
  
    const downloadBlob = (blob: Blob, filename: string): void => {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      setTimeout(() => URL.revokeObjectURL(url), 100);
    };
  
    const formatSpeed = (bytesPerSecond: number): string => {
      const effectiveSpeed = Math.max(bytesPerSecond, 1024);
  
      if (effectiveSpeed < 1024) {
        return `${effectiveSpeed.toFixed(1)} Б/с`;
      } else if (effectiveSpeed < 1024 * 1024) {
        return `${(effectiveSpeed / 1024).toFixed(1)} КБ/с`;
      } else {
        return `${(effectiveSpeed / (1024 * 1024)).toFixed(1)} МБ/с`;
      }
    };
  
    const formatSize = (bytes: number): string => {
      if (bytes < 1) return '0 Б';
  
      if (bytes < 1024) {
        return `${bytes} Б`;
      } else if (bytes < 1024 * 1024) {
        return `${(bytes / 1024).toFixed(1)} КБ`;
      } else if (bytes < 1024 * 1024 * 1024) {
        return `${(bytes / (1024 * 1024)).toFixed(1)} МБ`;
      } else {
        return `${(bytes / (1024 * 1024 * 1024)).toFixed(1)} ГБ`;
      }
    };
  
    const formatTime = (seconds: number): string => {
      if (seconds < 1) return 'меньше минуты';
  
      const limitedSeconds = Math.min(seconds, 86400);
  
      if (limitedSeconds < 60) {
        return `${Math.round(limitedSeconds)} сек`;
      } else if (limitedSeconds < 3600) {
        const minutes = Math.floor(limitedSeconds / 60);
        const remainingSeconds = Math.round(limitedSeconds % 60);
        return `${minutes} мин ${remainingSeconds} сек`;
      } else {
        const hours = Math.floor(limitedSeconds / 3600);
        const remainingMinutes = Math.floor((limitedSeconds % 3600) / 60);
        return `${hours} ч ${remainingMinutes} мин`;
      }
    };
  
    return (
      <div className="episode-downloader">
        {error && (
          <div className="download-error">
            <i className="fi fi-rr-exclamation"></i>
            <span>{error}</span>
          </div>
        )}
  
        {!isDownloading &&!showQualitySelector && (
          <button
            className="download-button"
            onClick={handleDownloadClick}
            disabled={!videoLinks || availableQualities.length === 0}
          >
            <i className="fi fi-rr-download"></i>
            <span>Скачать эпизод {currentEpisode}</span>
          </button>
        )}
  
        {showQualitySelector && (
          <div className="quality-selector-container">
            <p className="selector-title">Выберите качество для скачивания:</p>
            <div className="quality-options">
              {availableQualities.map((quality, index) => (
                <button
                  key={index}
                  className="quality-option"
                  onClick={() => startDownload(quality.value)}
                >
                  {quality.label}
                </button>
              ))}
            </div>
            <div className="concurrency-selector">
                <p className="selector-title">Количество потоков загрузки</p>
                <input
                    type="range"
                    min="1"
                    max="50"
                    value={concurrency}
                    onChange={(e) => updateConcurrency(parseInt(e.target.value, 10))}
                />
                <p className="concurrency-value">{concurrency}</p>
                {concurrency > 10 && (
                    <p className="concurrency-warning">
                    <i className="fi fi-rr-exclamation-triangle"></i>
                    Более 10 потоков может вызвать проблемы на медленном соединении
                    </p>
                )}
            </div>
            <button
              className="cancel-button"
              onClick={() => setShowQualitySelector(false)}
            >
              Отмена
            </button>
          </div>
        )}
  
        {isDownloading && (
          <div className="download-progress-container">
            <div className="download-header">
              <span className="download-title">
                Загрузка эпизода {currentEpisode}
              </span>
              <span className="download-percentage">
                {downloadProgress}%
              </span>
            </div>
  
            <div className="progress-bar-container">
              <div
                className="progress-bar"
                style={{ width: `${downloadProgress}%` }}
              ></div>
            </div>
  
            <div className="download-stats">
              <div className="stat-item">
                <i className="fi fi-rr-dashboard"></i>
                <span>Скорость: {formatSpeed(downloadSpeed)}</span>
              </div>
              <div className="stat-item">
                <i className="fi fi-rr-clock"></i>
                <span>Осталось: {estimatedTime > 0? formatTime(estimatedTime) : "Расчет..."}</span>
              </div>
              {totalSegments > 0 && (
                <div className="stat-item">
                  <i className="fi fi-rr-grid"></i>
                  <span>Сегменты: {downloadedSegments}/{totalSegments}</span>
                </div>
              )}
              <div className="stat-item">
                <i className="fi fi-rr-database"></i>
                <span>
                  Размер: {formatSize(downloadedBytes)}
                  {totalBytes > 0? `/${formatSize(totalBytes)}` : ""}
                </span>
              </div>
            </div>
  
            <div className="download-warning">
              <i className="fi fi-rr-info"></i>
              <span>Пожалуйста, не закрывайте страницу до завершения загрузки</span>
            </div>
  
            <button
              className="cancel-download-button"
              onClick={cancelDownload}
            >
              <i className="fi fi-rr-cross"></i>
              <span>Отменить загрузку</span>
            </button>
          </div>
        )}
      </div>
    );
  };
  
  export default EpisodeDownloader;
  