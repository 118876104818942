import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios'; 
import './MainPage.scss';
import { debounce } from 'lodash';
import "@flaticon/flaticon-uicons/css/all/all.css";
import { useNavigate, Link } from 'react-router-dom';

interface AnimeData {
  id: string;
  title?: {
    original: string;
    russian: string;
    english: string | null;
    japanese: string;
  };
  titles?: {
    original: string;
    russian: string;
    english: string | null;
    japanese: string;
  };
  poster?: string;
  images?: {
    poster: string;
  };
  score?: number;
  info?: {
    score: number;
    episodes: {
      aired: number;
    };
  };
  episodes?: {
    aired: number;
  };
}

interface TabSelectorProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

// Search filter interface
interface SearchFilters {
  status?: string;
  kind?: string;
  order?: string;
  genre?: string;
  score?: number;
  season?: string[];
  duration?: string;
  rating?: string;
  studio?: string;
  censored?: boolean;
}

// Genre data structure
interface GenreOption {
  id: string;
  name: string;
}

// Year/Season option structure
interface YearOption {
  value: string;
  label: string;
  icon: string;
}

// Multiple Year/Season selector component
const MultiYearSelector: React.FC<{
  onChange: (ranges: string[]) => void;
}> = ({ onChange }) => {
  const yearOptions: YearOption[] = [
    { value: "fall_2024", label: "Осень 2024", icon: "fi fi-rr-leaf" },
    { value: "summer_2025", label: "Лето 2025", icon: "fi fi-rr-sun" },
    { value: "spring_2025", label: "Весна 2025", icon: "fi fi-rr-flower-bouquet" },
    { value: "winter_2025", label: "Зима 2025", icon: "fi fi-rr-snowflake" },
    { value: "2025", label: "2025 год", icon: "fi fi-rr-calendar" },
    { value: "2024", label: "2024 год", icon: "fi fi-rr-calendar" },
    { value: "2022_2023", label: "2022-2023", icon: "fi fi-rr-calendar-lines" },
    { value: "2017_2021", label: "2017-2021", icon: "fi fi-rr-calendar-lines" },
    { value: "2010_2016", label: "2010-2016", icon: "fi fi-rr-calendar-lines" },
    { value: "2000_2010", label: "2000-2010", icon: "fi fi-rr-calendar-lines" },
    { value: "199x", label: "1990-е годы", icon: "fi fi-rr-time-past" },
    { value: "198x", label: "1980-е годы", icon: "fi fi-rr-time-past" }
  ];
  
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  
  const handleOptionToggle = (option: string) => {
    setSelectedOptions(prev => {
      const isSelected = prev.includes(option);
      const updated = isSelected 
        ? prev.filter(item => item !== option) 
        : [...prev, option];
      
      // Update parent component
      onChange(updated);
      return updated;
    });
  };
  
  return (
    <div className="multi-year-selector">
      <div className="selected-count">
        {selectedOptions.length > 0 
          ? `Выбрано: ${selectedOptions.length}` 
          : "Не выбран период"
        }
      </div>
      <div className="year-options">
        {yearOptions.map((option) => (
          <div 
            key={option.value}
            className={`year-option ${selectedOptions.includes(option.value) ? 'selected' : ''}`}
            onClick={() => handleOptionToggle(option.value)}
          >
            <i className={option.icon}></i>
            <span>{option.label}</span>
            {selectedOptions.includes(option.value) && 
              <i className="fi fi-rr-check check-icon"></i>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

// Dropdown Component
const Dropdown: React.FC<{
  label: string;
  value: string;
  options: {value: string, label: string, icon?: string}[];
  onChange: (value: string) => void;
  icon?: string;
}> = ({ label, value, options, onChange, icon }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <label>{label}</label>
      <div 
        className={`dropdown-header ${isOpen ? 'open' : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="selected-value">
          {icon && <i className={icon}></i>}
          {options.find(opt => opt.value === value)?.label || "Выберите..."}
        </div>
        <i className={`dropdown-arrow fi ${isOpen ? 'fi-rr-angle-up' : 'fi-rr-angle-down'}`}></i>
      </div>
      
      <div className={`dropdown-options ${isOpen ? 'open' : ''}`}>
        {options.map(option => (
          <div 
            key={option.value} 
            className={`dropdown-option ${value === option.value ? 'selected' : ''}`}
            onClick={() => {
              onChange(option.value);
              setIsOpen(false);
            }}
          >
            {option.icon && <i className={option.icon}></i>}
            {option.label}
            {value === option.value && <i className="fi fi-rr-check check-icon"></i>}
          </div>
        ))}
      </div>
    </div>
  );
};

// Advanced search modal component
const AdvancedSearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  onSearch: (term: string, filters: SearchFilters) => void;
  initialSearchTerm: string;
}> = ({ isOpen, onClose, onSearch, initialSearchTerm }) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [filters, setFilters] = useState<SearchFilters>({
    status: '',
    kind: '',
    order: 'popularity',
    genre: '',
    score: 0,
    season: [],
    duration: '',
    rating: '',
    studio: '',
    censored: true
  });
  
  // Available genres with correct IDs - removed adult content
  const genres: GenreOption[] = [
    { id: "8", name: "Драма" },
    { id: "11", name: "Игры" },
    { id: "40", name: "Психологическое" },
    { id: "19", name: "Музыка" },
    { id: "1", name: "Экшен" },
    { id: "4", name: "Комедия" },
    { id: "6", name: "Демоны" },
    { id: "39", name: "Полиция" },
    { id: "10", name: "Фэнтези" },
    { id: "13", name: "Исторический" },
    { id: "16", name: "Магия" },
    { id: "18", name: "Меха" },
    { id: "20", name: "Пародия" },
    { id: "21", name: "Самураи" },
    { id: "22", name: "Романтика" },
    { id: "23", name: "Школа" },
    { id: "27", name: "Сёнен" },
    { id: "32", name: "Вампиры" },
    { id: "35", name: "Гарем" },
    { id: "36", name: "Повседневность" },
    { id: "37", name: "Сверхъестественное" },
    { id: "41", name: "Триллер" },
    { id: "24", name: "Фантастика" },
    { id: "25", name: "Сёдзё" },
    { id: "31", name: "Супер сила" },
    { id: "38", name: "Военное" },
    { id: "7", name: "Детектив" },
    { id: "15", name: "Детское" },
    { id: "3", name: "Машины" },
    { id: "17", name: "Боевые искусства" },
    { id: "5", name: "Безумие" },
    { id: "30", name: "Спорт" },
    { id: "42", name: "Сэйнэн" },
    { id: "543", name: "Гурман" },
    { id: "29", name: "Космос" },
    { id: "541", name: "Работа" },
    { id: "14", name: "Ужасы" },
    { id: "2", name: "Приключения" }
  ];
  
  // Status options
  const statusOptions = [
    { value: "", label: "Любой", icon: "fi fi-rr-info" },
    { value: "anons", label: "Анонсировано", icon: "fi fi-rr-megaphone" },
    { value: "ongoing", label: "Сейчас выходит", icon: "fi fi-rr-play" },
    { value: "released", label: "Вышедшее", icon: "fi fi-rr-check" }
  ];
  
  // Type options
  const typeOptions = [
    { value: "", label: "Любой", icon: "fi fi-rr-info" },
    { value: "tv", label: "TV Сериал", icon: "fi fi-rr-tv" },
    { value: "movie", label: "Фильм", icon: "fi fi-rr-film" },
    { value: "ova", label: "OVA", icon: "fi fi-rr-video-camera" },
    { value: "ona", label: "ONA", icon: "fi fi-rr-cloud" },
    { value: "special", label: "Спецвыпуск", icon: "fi fi-rr-star" },
    { value: "tv_special", label: "TV Спецвыпуск", icon: "fi fi-rr-stars" },
    { value: "music", label: "Музыкальное", icon: "fi fi-rr-music" }
  ];
  
  // Sort options
  const sortOptions = [
    { value: "popularity", label: "По популярности", icon: "fi fi-rr-flame" },
    { value: "ranked", label: "По рейтингу", icon: "fi fi-rr-star" },
    { value: "name", label: "По алфавиту", icon: "fi fi-rr-text" },
    { value: "aired_on", label: "По дате выхода", icon: "fi fi-rr-calendar" },
    { value: "random", label: "Случайно", icon: "fi fi-rr-dice" }
  ];
  
  // Score options
  const scoreOptions = [
    { value: "", label: "Любая", icon: "fi fi-rr-info" },
    { value: "8", label: "8+", icon: "fi fi-rr-star" },
    { value: "7", label: "7+", icon: "fi fi-rr-star-half" },
    { value: "6", label: "6+", icon: "fi fi-rr-stars" }
  ];
  
  // Duration options
  const durationOptions = [
    { value: "", label: "Любая", icon: "fi fi-rr-info" },
    { value: "S", label: "До 10 минут", icon: "fi fi-rr-time-fast" },
    { value: "D", label: "До 30 минут", icon: "fi fi-rr-time-quarter-past" },
    { value: "F", label: "Более 30 минут", icon: "fi fi-rr-time-half-past" }
  ];
  
  // Rating options
  const ratingOptions = [
    { value: "", label: "Любой", icon: "fi fi-rr-info" },
    { value: "g", label: "G", icon: "fi fi-rr-child-head" },
    { value: "pg", label: "PG", icon: "fi fi-rr-user" },
    { value: "pg_13", label: "PG-13", icon: "fi fi-rr-user-time" },
    { value: "r", label: "R-17", icon: "fi fi-rr-user-lock" },
    { value: "r_plus", label: "R+", icon: "fi fi-rr-shield-exclamation" }
  ];
  
  // Selected genres (to support multi-select)
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  
  const modalRef = useRef<HTMLDivElement>(null);
  
  // Handle click outside to close modal
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);
  
  // Prevent scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);
  
  const handleFilterChange = (key: keyof SearchFilters, value: any) => {
    setFilters(prev => ({ ...prev, [key]: value }));
  };
  
  const handleGenreToggle = (genreId: string) => {
    setSelectedGenres(prev => {
      const isSelected = prev.includes(genreId);
      
      if (isSelected) {
        return prev.filter(id => id !== genreId);
      } else {
        return [...prev, genreId];
      }
    });
  };
  
  useEffect(() => {
    // Update genre filter string when selected genres change
    if (selectedGenres.length > 0) {
      handleFilterChange('genre', selectedGenres.join(','));
    } else {
      handleFilterChange('genre', '');
    }
  }, [selectedGenres]);
  
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Prepare the cleaned filters
    const cleanedFilters = { ...filters };
    
    // Convert score to number if it exists
    if (cleanedFilters.score) {
      cleanedFilters.score = Number(cleanedFilters.score);
    }
    
    // Pass the cleaned filters directly, keeping season as string[]
    if (cleanedFilters.season && Array.isArray(cleanedFilters.season) && cleanedFilters.season.length > 0) {
      onSearch(searchTerm, cleanedFilters);
    } else {
      onSearch(searchTerm, {...cleanedFilters, season: []});
    }
    
    onClose();
  };
  
  const handleReset = () => {
    setFilters({
      status: '',
      kind: '',
      order: 'popularity',
      genre: '',
      score: 0,
      season: [],
      duration: '',
      rating: '',
      studio: '',
      censored: true
    });
    setSelectedGenres([]);
  };
  
  if (!isOpen) return null;
  
  return (
    <div className="advanced-search-overlay">
      <div className="advanced-search-modal" ref={modalRef}>
        <div className="modal-header">
          <h2>
            <i className="fi fi-rr-settings-sliders"></i>
            Расширенный поиск
          </h2>
          <button className="close-btn" onClick={onClose}>
            <i className="fi fi-rr-cross"></i>
          </button>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="search-input-wrapper">
            <i className="fi fi-rr-search"></i>
            <input
              type="text"
              placeholder="Введите название аниме..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoFocus
            />
          </div>
          
          <div className="filters-grid">
            {/* Status dropdown */}
            <Dropdown 
              label="Статус"
              value={filters.status || ''}
              options={statusOptions}
              onChange={(value) => handleFilterChange('status', value)}
              icon="fi fi-rr-info"
            />
            
            {/* Type dropdown */}
            <Dropdown 
              label="Тип"
              value={filters.kind || ''}
              options={typeOptions}
              onChange={(value) => handleFilterChange('kind', value)}
              icon="fi fi-rr-film"
            />
            
            {/* Sort dropdown */}
            <Dropdown 
              label="Сортировка"
              value={filters.order || 'popularity'}
              options={sortOptions}
              onChange={(value) => handleFilterChange('order', value)}
              icon="fi fi-rr-sort"
            />
            
            {/* Score dropdown */}
            <Dropdown 
              label="Минимальная оценка"
              value={filters.score?.toString() || ''}
              options={scoreOptions}
              onChange={(value) => handleFilterChange('score', value ? Number(value) : '')}
              icon="fi fi-rr-star"
            />
            
            {/* Duration dropdown */}
            <Dropdown 
              label="Длительность эпизода"
              value={filters.duration || ''}
              options={durationOptions}
              onChange={(value) => handleFilterChange('duration', value)}
              icon="fi fi-rr-clock"
            />
            
            {/* Rating dropdown */}
            <Dropdown 
              label="Рейтинг"
              value={filters.rating || ''}
              options={ratingOptions}
              onChange={(value) => handleFilterChange('rating', value)}
              icon="fi fi-rr-badge"
            />
          </div>
          
          {/* Season/Year multi-selector */}
          <div className="year-selector-section">
            <h3>
              <i className="fi fi-rr-calendar"></i>
              Сезон и год выпуска
            </h3>
            <MultiYearSelector 
              onChange={(seasons) => handleFilterChange('season', seasons)}
            />
          </div>
          
          {/* Genres section */}
          <div className="genres-section">
            <h3>
              <i className="fi fi-rr-list"></i>
              Жанры
            </h3>
            <div className="genres-grid">
              {genres.map(genre => (
                <div 
                  key={genre.id}
                  className={`genre-chip ${selectedGenres.includes(genre.id) ? 'selected' : ''}`}
                  onClick={() => handleGenreToggle(genre.id)}
                >
                  {genre.name}
                  {selectedGenres.includes(genre.id) && 
                    <i className="fi fi-rr-check check-icon"></i>
                  }
                </div>
              ))}
            </div>
          </div>
          
          <div className="modal-footer">
            <button type="button" className="reset-btn" onClick={handleReset}>
              <i className="fi fi-rr-refresh"></i>
              Сбросить
            </button>
            <button type="submit" className="search-btn">
              <i className="fi fi-rr-search"></i>
              Искать
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// Anime Card Component
const AnimeCard: React.FC<{ anime: AnimeData }> = ({ anime }) => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  
  // Handle mouse movement for parallax effect
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!cardRef.current) return;
    
    const card = cardRef.current;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const moveX = (x - centerX) / 20;
    const moveY = (y - centerY) / 20;
    
    card.style.setProperty('--mouse-x', `${x}px`);
    card.style.setProperty('--mouse-y', `${y}px`);
    
    if (card.querySelector('img')) {
      (card.querySelector('img') as HTMLElement).style.transform = `translateX(${moveX}px) translateY(${moveY}px) scale(${isHovered ? 1.05 : 1})`;
    }
  };
  
  return (
    <div 
      ref={cardRef}
      className="anime-card" 
      onClick={() => navigate(`/anime/${anime.id}`)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        if (cardRef.current && cardRef.current.querySelector('img')) {
          (cardRef.current.querySelector('img') as HTMLElement).style.transform = 'translateX(0) translateY(0) scale(1)';
        }
      }}
      onMouseMove={handleMouseMove}
    >
      <div className="image-container">
        {!imageLoaded && <div className="image-skeleton" />}
        <img 
          src={anime.images?.poster || anime.poster}
          alt={anime.titles?.russian || anime.title?.russian} 
          className={imageLoaded ? 'loaded' : ''}
          onLoad={() => setImageLoaded(true)}
          loading="lazy"
        />
        <div className="anime-episodes">
          <span>{anime.info?.episodes?.aired || anime.episodes?.aired || 0}</span>
          <span className="episodes-label">эп.</span>
        </div>
        <div className="card-overlay"></div>
      </div>
      <div className="anime-info">
        <h3 className="anime-title">
          {anime.titles?.russian || anime.title?.russian}
        </h3>
        <div className="anime-rating">
          <i className="fi fi-rr-star"></i>
          <span>{anime.info?.score?.toFixed(1) || anime.score?.toFixed(1) || "0.0"}</span>
        </div>
      </div>
      {isHovered && <div className="card-shine"></div>}
    </div>
  );
};

const TabSelector: React.FC<TabSelectorProps> = ({ activeTab, onTabChange }) => {
  return (
    <div className="tabs-container">
      <div 
        className={`tab ${activeTab === 'all_time' ? 'active' : ''}`}
        onClick={() => onTabChange('all_time')}
      >
        <i className="fi fi-rr-star"></i>
        <span>Популярные</span>
      </div>
      <div 
        className={`tab ${activeTab === 'ongoing_2024_2025' ? 'active' : ''}`}
        onClick={() => onTabChange('ongoing_2024_2025')}
      >
        <i className="fi fi-rr-play"></i>
        <span>Выходят</span>
      </div>
      <div 
        className={`tab ${activeTab === 'completed_2024_2025' ? 'active' : ''}`}
        onClick={() => onTabChange('completed_2024_2025')}
      >
        <i className="fi fi-rr-badge"></i>
        <span>Топ 2024 - 2025</span>
      </div>
    </div>
  );
};

const MainPage: React.FC = () => {
  // Anime lists states
  const [allTimeAnime, setAllTimeAnime] = useState<AnimeData[]>([]);
  const [ongoingAnime, setOngoingAnime] = useState<AnimeData[]>([]);
  const [completedAnime, setCompletedAnime] = useState<AnimeData[]>([]);
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [searchResults, setSearchResults] = useState<AnimeData[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('all_time');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const mainContentRef = useRef<HTMLDivElement>(null);
  
  // Advanced search state
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [currentSearchFilters, setCurrentSearchFilters] = useState<SearchFilters>({});

  const SearchResults: React.FC = () => (
    <div className="search-results">
      <div className="search-controls">
        <button 
          className="close-search-button" 
          onClick={() => {
            setIsSearchMode(false);
            setSearchTerm('');
            setSearchResults([]);
          }}
        >
          <i className="fi fi-rr-arrow-left"></i>
          Назад
        </button>
        <div className="search-info">
          {isLoading ? (
            <div className="searching-animation">
              <span>Поиск</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
              <span className="dot">.</span>
            </div>
          ) : (
            <span>Найдено: {searchResults.length}</span>
          )}
        </div>
      </div>
      <div className="search-content">
        {searchResults.length > 0 ? (
          <div className="anime-grid">
            {searchResults.map((anime) => (
              <AnimeCard key={anime.id} anime={anime} />
            ))}
          </div>
        ) : (
          <div className="no-results">
            {isLoading ? (
              <div className="loader-container">
                <div className="fancy-loader">
                  <div className="inner-circle"></div>
                </div>
                <p>Ищем самое интересное для вас...</p>
              </div>
            ) : (
              <div className="no-results-content">
                <i className="fi fi-rr-search-alt"></i>
                <p>По запросу "{searchTerm}" ничего не найдено</p>
                <span>Попробуйте изменить параметры поиска</span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );

  const [pages, setPages] = useState({
    all_time: 1,
    ongoing_2024_2025: 1,
    completed_2024_2025: 1
  });

  const [hasMoreData, setHasMoreData] = useState({
    all_time: true,
    ongoing_2024_2025: true,
    completed_2024_2025: true
  });

  const loadTabData = async (tab: string, page: number) => {
    try {
      const response = await axios.get(`https://aninew.ru/api/anime/ongoing?page=${page}&mode=${tab}`);
      const data = Array.isArray(response.data) ? response.data : [];
      
      switch(tab) {
        case 'all_time':
          setAllTimeAnime(prev => [...prev, ...data]);
          break;
        case 'ongoing_2024_2025':
          setOngoingAnime(prev => [...prev, ...data]);
          break;
        case 'completed_2024_2025':
          setCompletedAnime(prev => [...prev, ...data]);
          break;
      }
  
      setHasMoreData(prev => ({
        ...prev,
        [tab]: data.length > 0
      }));
  
      return data.length > 0;
    } catch (error) {
      console.error(`Ошибка загрузки данных для ${tab}:`, error);
      return false;
    }
  };
  
  // Load all lists on mount
  useEffect(() => {
    const loadAllLists = async () => {
      setIsLoading(true);
      try {
        const [allTime, ongoing, completed] = await Promise.all([
          axios.get(`https://aninew.ru/api/anime/ongoing?page=1&mode=all_time`),
          axios.get(`https://aninew.ru/api/anime/ongoing?page=1&mode=ongoing_2024_2025`),
          axios.get(`https://aninew.ru/api/anime/ongoing?page=1&mode=completed_2024_2025`)
        ]);

        setAllTimeAnime(Array.isArray(allTime.data) ? allTime.data : []);
        setOngoingAnime(Array.isArray(ongoing.data) ? ongoing.data : []);
        setCompletedAnime(Array.isArray(completed.data) ? completed.data : []);
        
        // Animate initial cards
        setTimeout(() => {
          document.querySelectorAll('.anime-card').forEach((card, index) => {
            setTimeout(() => {
              card.classList.add('visible');
            }, index * 50);
          });
        }, 300);
        
      } catch (error) {
        console.error('Ошибка загрузки данных:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadAllLists();
    
    // Add scroll animation effect
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1, rootMargin: "0px 0px -100px 0px" }
    );

    // Add particle effects
    addParticleEffects();
    
    return () => observer.disconnect();
  }, []);
  
  // Add particle effects to background
  const addParticleEffects = () => {
    const container = document.createElement('div');
    container.className = 'particles-container';
    
    for (let i = 0; i < 50; i++) {
      const particle = document.createElement('div');
      particle.className = 'particle';
      
      // Random position and size
      particle.style.left = `${Math.random() * 100}%`;
      particle.style.top = `${Math.random() * 100}%`;
      particle.style.width = `${Math.random() * 3 + 1}px`;
      particle.style.height = particle.style.width;
      
      // Random delay and duration
      particle.style.animationDelay = `${Math.random() * 5}s`;
      particle.style.animationDuration = `${Math.random() * 10 + 10}s`;
      
      container.appendChild(particle);
    }
    
    document.body.appendChild(container);
  };

  // Card animation effect
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1 }
    );

    document.querySelectorAll('.anime-card').forEach(card => {
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, [allTimeAnime, ongoingAnime, completedAnime, searchResults]);

  useEffect(() => {
    const currentList = activeTab === 'all_time' 
      ? allTimeAnime 
      : activeTab === 'ongoing_2024_2025'
        ? ongoingAnime
        : completedAnime;
  
    if (currentList.length === 0 && hasMoreData[activeTab as keyof typeof hasMoreData]) {
      loadMoreAnime();
    }
  }, [activeTab]);

  // Load more anime on scroll
  const loadMoreAnime = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);
      const currentPage = pages[activeTab as keyof typeof pages];
      
      const response = await axios.get(
        `https://aninew.ru/api/anime/ongoing?page=${currentPage}&mode=${activeTab}`
      );
      
      const data = Array.isArray(response.data) ? response.data : [];

      // Update the appropriate list based on active tab
      switch(activeTab) {
        case 'all_time':
          setAllTimeAnime(prev => [...prev, ...data]);
          break;
        case 'ongoing_2024_2025':
          setOngoingAnime(prev => [...prev, ...data]);
          break;
        case 'completed_2024_2025':
          setCompletedAnime(prev => [...prev, ...data]);
          break;
      }

      // Update page for current tab
      setPages(prev => ({
        ...prev,
        [activeTab]: currentPage + 1
      }));

      // Update hasMore status for current tab
      setHasMoreData(prev => ({
        ...prev,
        [activeTab]: data.length > 0
      }));

      // Add animation for newly loaded cards
      setTimeout(() => {
        document.querySelectorAll('.anime-card:not(.visible)').forEach((card, index) => {
          setTimeout(() => {
            card.classList.add('visible');
          }, index * 50);
        });
      }, 100);

    } catch (error) {
      console.error('Ошибка загрузки аниме:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabChange = (tab: string) => {
    // First, add previous class to current active tab
    document.querySelectorAll('.anime-list.active').forEach(el => {
      el.classList.add('previous');
      setTimeout(() => {
        el.classList.remove('previous');
      }, 500); // Match your transition time
    });
    
    setActiveTab(tab);
    
    // Check if data needs to be loaded
    if ((tab === 'all_time' && allTimeAnime.length === 0) ||
        (tab === 'ongoing_2024_2025' && ongoingAnime.length === 0) ||
        (tab === 'completed_2024_2025' && completedAnime.length === 0)) {
      setPages(prev => ({
        ...prev,
        [tab]: 1
      }));
      loadMoreAnime();
    }
    
    // Scroll to top when changing tabs
    if (mainContentRef.current) {
      mainContentRef.current.scrollTop = 0;
    }
  };

  // Simple search handler
  const handleSimpleSearch = async () => {
    if (searchTerm.trim()) {
      try {
        setIsLoading(true);
        setIsSearchMode(true);
        const response = await axios.get(`https://aninew.ru/api/anime/search/${searchTerm}`);
        const data = Array.isArray(response.data) ? response.data : [];
        setSearchResults(data);
        
        // Add animation for search results
        setTimeout(() => {
          document.querySelectorAll('.anime-card:not(.visible)').forEach((card, index) => {
            setTimeout(() => {
              card.classList.add('visible');
            }, index * 50);
          });
        }, 300);
      } catch (error) {
        console.error('Ошибка поиска:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsSearchMode(false);
      setSearchResults([]);
    }
  };
  
  // Advanced search handler
  const handleAdvancedSearch = async (term: string, filters: SearchFilters) => {
    if (!term.trim() && Object.values(filters).every(val => !val)) {
      return; // Don't search if no term and no filters are provided
    }
    
    try {
      setIsLoading(true);
      setIsSearchMode(true);
      setSearchTerm(term);
      setCurrentSearchFilters(filters);
      
      // Build query params
      const params = new URLSearchParams();
      if (term) params.append('query', term);
      if (filters.status) params.append('status', filters.status);
      if (filters.kind) params.append('kind', filters.kind);
      if (filters.order) params.append('order', filters.order);
      if (filters.genre) params.append('genre', filters.genre);
      if (filters.score) params.append('score', filters.score.toString());
      if (filters.season) params.append('season', filters.season.toString());
      if (filters.duration) params.append('duration', filters.duration);
      if (filters.rating) params.append('rating', filters.rating);
      if (filters.studio) params.append('studio', filters.studio);
      if (filters.censored !== undefined) params.append('censored', filters.censored.toString());
      
      const response = await axios.get(`https://aninew.ru/api/anime/advanced_search?${params.toString()}`);
      const data = Array.isArray(response.data) ? response.data : [];
      setSearchResults(data);
      
      // Add animation for search results
      setTimeout(() => {
        document.querySelectorAll('.anime-card:not(.visible)').forEach((card, index) => {
          setTimeout(() => {
            card.classList.add('visible');
          }, index * 50);
        });
      }, 300);
    } catch (error) {
      console.error('Ошибка расширенного поиска:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTabScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;
  
    if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading && hasMoreData[activeTab as keyof typeof hasMoreData]) {
      loadMoreAnime();
    }
  };

  useEffect(() => {
    const accessToken = document.cookie
      .split('; ')
      .find(row => row.startsWith('access_token='));
    setIsAuthenticated(!!accessToken);
  }, []);
  
  // Cleanup particle effects on unmount
  useEffect(() => {
    return () => {
      const particlesContainer = document.querySelector('.particles-container');
      if (particlesContainer) {
        particlesContainer.remove();
      }
    };
  }, []);

  return (
    <div className="main-page">
      <div className="particles-background"></div>
      
      <header className="header">
        <Link to="/main" className="logo">
          <img src="https://i.postimg.cc/C18c8DR0/image-1.png" alt="AniNew" />
        </Link>
        <div className="search-bar">
          <input 
            type="text"
            placeholder="Поиск аниме..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleSimpleSearch();
              }
            }}
          />
          <button 
            className="advanced-search-btn" 
            onClick={() => setIsAdvancedSearchOpen(true)}
            title="Расширенный поиск"
          >
            <i className="fi fi-rr-settings-sliders"></i>
          </button>
          <button className="search-button" onClick={handleSimpleSearch}>
            <i className="fi fi-rr-search search-icon"></i>
          </button>
        </div>
        {isAuthenticated ? (
          <Link to="/profile" className="profile">
            <i className="fi fi-rr-user"></i>
          </Link>
        ) : (
          <Link to="/auth" className="profile">
            <i className="fi fi-rr-user"></i>
          </Link>
        )}
      </header>

      {!isSearchMode && (
        <TabSelector activeTab={activeTab} onTabChange={handleTabChange} />
      )}

      <div className="content-wrapper">
        <main className="main-content" ref={mainContentRef}>
          {isSearchMode ? (
            <SearchResults />
          ) : (
            <div className="anime-lists-container">
              <div 
                className={`anime-list ${activeTab === 'all_time' ? 'active' : ''}`}
                onScroll={handleTabScroll}
              >
                <div className="anime-grid">
                  {Array.isArray(allTimeAnime) ? allTimeAnime.map((anime) => (
                    <AnimeCard key={anime.id} anime={anime} />
                  )) : null}
                </div>
              </div>

              <div 
                className={`anime-list ${activeTab === 'ongoing_2024_2025' ? 'active' : ''}`}
                onScroll={handleTabScroll}
              >
                <div className="anime-grid">
                  {Array.isArray(ongoingAnime) ? ongoingAnime.map((anime) => (
                    <AnimeCard key={anime.id} anime={anime} />
                  )) : null}
                </div>
              </div>

              <div 
                className={`anime-list ${activeTab === 'completed_2024_2025' ? 'active' : ''}`}
                onScroll={handleTabScroll}
              >
                <div className="anime-grid">
                  {Array.isArray(completedAnime) ? completedAnime.map((anime) => (
                    <AnimeCard key={anime.id} anime={anime} />
                  )) : null}
                </div>
              </div>
            </div>
          )}
          
          {isLoading && !isSearchMode && (
            <div className="loading">
              <div className="loading-spinner"></div>
            </div>
          )}
        </main>
      </div>
      
      {/* Advanced Search Modal */}
      <AdvancedSearchModal 
        isOpen={isAdvancedSearchOpen}
        onClose={() => setIsAdvancedSearchOpen(false)}
        onSearch={handleAdvancedSearch}
        initialSearchTerm={searchTerm}
      />
    </div>
  );
};

export default MainPage;
