import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './ChatPage.scss';
import "@flaticon/flaticon-uicons/css/all/all.css";

interface Character {
  id: string;
  name: string;
  anime: string;
  image: string;
}

interface CharacterInfo {
  name: {
    original: string;
    russian: string;
    japanese: string;
  };
  image: {
    original: string;
    preview: string;
  };
  description: string;
  animes: Array<{
    id: number;
    title: {
      original: string;
      russian: string;
    };
    image: string;
    role: string;
  }>;
}

const ChatPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0);
  const [characterInfo, setCharacterInfo] = useState<CharacterInfo | null>(null);
  const [messages, setMessages] = useState<Array<{text: string, sender: 'user' | 'character'}>>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [character, setCharacter] = useState<Character | null>(null);
  const [isTyping, setIsTyping] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const loadingStages = [
    "Поиск информации о персонаже...",
    "Изучение лора мира...",
    "Анализ характера персонажа...",
    "Настройка нейросети...",
    "Инициализация диалога...",
    "Запуск суперкомпьютера..."
  ];

  // Scroll to bottom when messages update
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const checkCharacter = async () => {
      try {
        const characterPromise = axios.get(`/api/character/${id}`);
        
        // More engaging loading animation with random times
        const animationInterval = setInterval(() => {
          setLoadingStage(prev => {
            const nextStage = prev + 1;
            if (nextStage >= loadingStages.length) {
              clearInterval(animationInterval);
              return prev;
            }
            return nextStage;
          });
        }, 2000 + Math.random() * 2000); // Random time between 2-4 seconds
  
        const response = await characterPromise;
        clearInterval(animationInterval);
        setCharacterInfo(response.data);
        setCharacter(response.data);
        
        // Add a welcome message from the character after loading
        setTimeout(() => {
          setIsLoading(false);
          setTimeout(() => {
            setMessages([{
              text: `Привет! Я ${response.data.name.russian || response.data.name.original}. Как я могу помочь тебе сегодня?`,
              sender: 'character'
            }]);
          }, 500);
        }, 1000);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };
    
    checkCharacter();
    
    // Focus on input when component mounts
    return () => {
      // Cleanup any resources if needed
    };
  }, [id, loadingStages.length]);

  // Focus on input after loading is complete
  useEffect(() => {
    if (!isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isLoading]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim() || isTyping) return;
  
    // Add user message
    setMessages(prev => [...prev, {
      text: inputMessage,
      sender: 'user'
    }]);
    
    // Show typing indicator
    setIsTyping(true);
    
    const messageToBeSent = inputMessage;
    setInputMessage('');
  
    try {
      const response = await fetch(`/api/character/${id}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify([...messages, { text: messageToBeSent, sender: 'user' }])
      });
  
      if (!response.ok) {
        throw new Error('Ошибка сети');
      }
  
      const reader = response.body?.getReader();
      if (!reader) throw new Error('Не удалось получить reader');
  
      let accumulatedText = '';
      let isFirstChunk = true;
      
      // Add typing message placeholder
      setMessages(prev => [...prev, {
        text: '',
        sender: 'character'
      }]);
  
      while (true) {
        const { done, value } = await reader.read();
        
        if (done) {
          setIsTyping(false);
          break;
        }
  
        // Decode received data
        const chunk = new TextDecoder().decode(value);
        
        // Process each line separately
        const lines = chunk.split('\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const content = line.slice(6); // Remove 'data: ' prefix
            
            // If it's the first chunk, add a slight delay for more realistic typing
            if (isFirstChunk) {
              await new Promise(resolve => setTimeout(resolve, 500));
              isFirstChunk = false;
            }
            
            accumulatedText += content;
            
            // Update the last message with new text
            setMessages(prev => {
              const newMessages = [...prev];
              newMessages[newMessages.length - 1].text = accumulatedText;
              return newMessages;
            });
            
            // Add a slight delay between chunks for typing effect
            await new Promise(resolve => setTimeout(resolve, 30));
          }
        }
      }
  
    } catch (error) {
      console.error('Ошибка:', error);
      setMessages(prev => [...prev, {
        text: 'Извини, у меня возникла проблема при ответе. Попробуй спросить что-нибудь другое.',
        sender: 'character'
      }]);
      setIsTyping(false);
    }
  };

  const handleBackClick = () => {
    // Add exit animation before navigating back
    const chatContainer = document.querySelector('.chat-container');
    if (chatContainer) {
      chatContainer.classList.add('exit-animation');
      setTimeout(() => navigate(-1), 300);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="chat-page">
      {isLoading ? (
        <div className="loading-screen">
          <div className="loading-container">
            <div className="loading-animation"></div>
            <div className="loading-particles">
              {[...Array(10)].map((_, i) => (
                <div key={i} className="particle"></div>
              ))}
            </div>
          </div>
          <div className="loading-text">
            {loadingStages[loadingStage]}
          </div>
          <div className="loading-progress">
            <div 
              className="loading-progress-bar" 
              style={{ width: `${(loadingStage + 1) / loadingStages.length * 100}%` }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="chat-container">
          {characterInfo && (
            <div className="chat-header">
              <button className="back-button ripple" onClick={handleBackClick}>
                <i className="fi fi-rr-arrow-left"></i>
              </button>
              
              <div className="character-avatar-container">
                <div className="avatar-shine"></div>
                <img 
                  src={`https://shikimori.one${characterInfo.image.original}`}
                  alt={characterInfo.name.russian || characterInfo.name.original}
                  className="character-avatar"
                />
              </div>
              
              <div className="character-info">
                <h2 className="character-name">{characterInfo.name.russian || characterInfo.name.original}</h2>
                <p className="character-anime">
                  {characterInfo.animes && characterInfo.animes.length > 0 
                    ? characterInfo.animes[0].title.russian || characterInfo.animes[0].title.original
                    : ''}
                </p>
              </div>
            </div>
          )}
          
          <div className="chat-messages custom-scrollbar">
            {messages.map((message, index) => (
              <div 
                key={index} 
                className={`message ${message.sender}-message ${index === messages.length - 1 ? 'last-message' : ''}`}
              >
                {message.text}
                <div className="message-time">
                  {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </div>
              </div>
            ))}
            
            {isTyping && (
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
            
            <div ref={messagesEndRef} />
          </div>
          
          <div className="chat-input">
            <div className="emoji-button ripple" title="Эмодзи">
              <i className="fi fi-rr-smile"></i>
            </div>
            
            <div className="input-container">
              <input
                ref={inputRef}
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSendMessage();
                }}
                placeholder="Введите сообщение..."
                disabled={isTyping}
              />
              
              {inputMessage && (
                <button className="clear-button" onClick={() => setInputMessage('')}>
                  <i className="fi fi-rr-cross-small"></i>
                </button>
              )}
            </div>
            
            <button 
              className={`send-button ripple ${!inputMessage.trim() || isTyping ? 'disabled' : ''}`} 
              onClick={handleSendMessage}
              disabled={!inputMessage.trim() || isTyping}
            >
              <i className="fi fi-rr-paper-plane"></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatPage;
