import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

// Объявляем типы для источников видео
interface VideoSourceDirect {
  quality: number;
  url: string;
}

interface VideoLinks {
  direct: VideoSourceDirect[];
  kodik: string[];
}

// Основной компонент страницы с плеером
const VideoPlayerPage: React.FC = () => {
  // DOM-рефы
  const videoNodeRef = useRef<HTMLVideoElement | null>(null);
  const playerInstanceRef = useRef<any>(null);
  
  // Состояния
  const [sourceType, setSourceType] = useState<'direct' | 'kodik'>('direct');
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  
  // Тестовые источники видео
  const videoSources: VideoLinks = {
    direct: [
      {
        quality: 1080,
        url: "https://ht.nuplink2.net/pool/pub/d7/f8/79/f1d126c8626b70e6c785bc50add7f879/624351273?token=cd262cbebe5c11b4852e2c002e80a197&ts=1740917650&ht=video/mp4"
      },
      {
        quality: 2160,
        url: "https://ht.nuplink2.net/pool/pub/e7/82/f9/ffc273b32c2542c38a1009a275e782f9/1222155487?token=33577f5224c76adbde0fb99992b90e4d&ts=1740917651&ht=video/mp4"
      }
    ],
    kodik: [
      "//cloud.kodik-storage.com/useruploads/af89436a-64ca-4190-8462-a52810731ab8/63cdcf0001f2d3e7a4a7290073b5fa8d:2025030300/360.mp4:hls:manifest.m3u8",
      "//cloud.kodik-storage.com/useruploads/af89436a-64ca-4190-8462-a52810731ab8/63cdcf0001f2d3e7a4a7290073b5fa8d:2025030300/480.mp4:hls:manifest.m3u8",
      "//cloud.kodik-storage.com/useruploads/af89436a-64ca-4190-8462-a52810731ab8/63cdcf0001f2d3e7a4a7290073b5fa8d:2025030300/720.mp4:hls:manifest.m3u8"
    ]
  };

  // Форматирование качества для отображения
  const formatQuality = (quality: number): string => {
    switch (quality) {
      case 2160: return '4K';
      case 1080: return 'FullHD';
      default: return `${quality}p`;
    }
  };

  // Получение форматированных источников для плеера
  const getFormattedSources = () => {
    if (sourceType === 'direct') {
      return videoSources.direct.map(source => ({
        src: source.url,
        type: 'video/mp4',
        label: formatQuality(source.quality),
        selected: source.quality === 1080
      }));
    } else { // kodik
      return videoSources.kodik.map((source, index) => {
        const qualityMatch = source.match(/(\d+)\.mp4/);
        const quality = qualityMatch ? parseInt(qualityMatch[1]) : 0;
        return {
          src: source.startsWith('http') ? source : `https:${source}`,
          type: 'application/x-mpegURL',
          label: formatQuality(quality),
          selected: index === videoSources.kodik.length - 1
        };
      });
    }
  };

  // Переключение типа источника
  const toggleSourceType = () => {
    setSourceType(prevType => prevType === 'direct' ? 'kodik' : 'direct');
  };

  // Эффект для инициализации плеера
  useEffect(() => {
    // Убедимся, что у нас есть DOM-элемент
    if (!videoNodeRef.current) return;

    // Опции для плеера
    const videoOptions = {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      sources: getFormattedSources()
    };

    // Создаем экземпляр плеера
    const player = videojs(videoNodeRef.current, videoOptions);
    
    player.ready(() => {
      console.log('Player is ready');
      setIsPlayerReady(true);
    });

    playerInstanceRef.current = player;

    // Очистка при размонтировании компонента
    return () => {
      if (playerInstanceRef.current) {
        playerInstanceRef.current.dispose();
        playerInstanceRef.current = null;
      }
    };
  }, []);

  // Эффект для обновления источников видео при смене типа
  useEffect(() => {
    if (playerInstanceRef.current && isPlayerReady) {
      playerInstanceRef.current.src(getFormattedSources());
    }
  }, [sourceType, isPlayerReady]);

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Video.js Player Demo</h1>
      
      <div style={{ marginBottom: '20px' }}>
        <div data-vjs-player>
          <video
            ref={videoNodeRef}
            className="video-js vjs-big-play-centered"
            width="100%"
            height="auto"
            controls
          />
        </div>
      </div>
      
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <button 
          onClick={toggleSourceType}
          style={{
            padding: '10px 20px',
            backgroundColor: '#2196F3',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '16px'
          }}
        >
          Switch to {sourceType === 'direct' ? 'Kodik' : 'Direct'} Source
        </button>
      </div>

      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        Current source type: <strong>{sourceType === 'direct' ? 'Direct MP4' : 'Kodik HLS'}</strong>
      </div>
    </div>
  );
};

export default VideoPlayerPage;
