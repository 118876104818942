import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { AuthProvider } from './components/AuthProvider';
import AdminPanel from './pages/AdminPanel';
import HomePage from './pages/HomePage';
import MainPage from './pages/MainPage';
import AnimePage from './pages/AnimePage';
import AuthPage from './pages/AuthPage';
import ProfilePage from './pages/ProfilePage';
import UploadPage from './pages/UploadPage';
import ChatPage from './pages/ChatPage';
import Test from './pages/test';

function App() {
  return (
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/auth" element={<AuthPage />} />
            <Route path="/main" element={<MainPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/admin/upload" element={<UploadPage/>} />
            <Route path="/anime/:id" element={<AnimePage />} />
            <Route path="/chat/:id" element={<ChatPage />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </AuthProvider>
      </Router>
  );
}

export default App;