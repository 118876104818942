import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './AnimePage.scss';
import { useParams, Link } from 'react-router-dom';
import { getCookie } from '../utils/auth';
import AnimePlayer from '../components/AnimePlayer';
import "@flaticon/flaticon-uicons/css/all/all.css";
import MusicPlayer from '../components/MusicPlayer';

interface Genre {
  id: string;
  name: string;
  russian: string;
  kind?: string;
}

interface ListActionButtonProps {
  animeId: string;
  poster: string;
  title: string;
  genres: Genre[];
}

interface AnimeData {
  id: string;
  titles: {
    original: string;
    russian: string;
    english: string | null;
    japanese: string;
  };
  info: {
    kind: string;
    status: string;
    episodes: {
      total: number;
      aired: number;
      duration?: number;
      next_episode?: string;
    };
    season: string | null;
    score: number;
    rating?: string;
  };
  images: {
    poster: {
      original: string;
    };
    screenshots: Array<{
      original: string;
      preview: string;
    }>
  };
  genres: Array<{
    id: string;
    name: string;
    russian: string;
    kind?: string;
  }>;
  characters: Array<{
    id: string;
    name: string;
    russian: string;
    role: string;
    poster: {
      original: string;
      preview: string;
    };
  }>;
  description: string;
  related: Array<{
    id: string;
    type: string;
    titles: {
      russian: string;
    };
    poster: string;
    relation_text?: string;
    name?: string;
  }>;
  music: Array<{
    title: string;
    artist: string;
    type: string;
    content_type: string;
    thumbnail: string;
  }>;
}

interface Voice {
  name: string;
  max_quality: number;
}

interface AnimeProgress {
  animeId: string;
  episode: number;
  timestamp: number;
  voiceName: string;
  quality: number;
}

const ListActionButton: React.FC<ListActionButtonProps> = ({ animeId, poster, title, genres }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Reset the current status when animeId changes
    setCurrentStatus(null);
    
    const fetchCurrentStatus = async () => {
      try {
        const access_token = getCookie('access_token');
        if (!access_token) return;
        
        const response = await axios.get('https://aninew.ru/api/me', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });
        const lists = ['watching', 'completed', 'planned', 'dropped', 'favorites'];
        for (const list of lists) {
          if (response.data[list]?.some((item: any) => item.id === animeId)) {
            setCurrentStatus(list);
            break;
          }
        }
      } catch (error) {
        console.error('Error fetching status:', error);
      }
    };
    fetchCurrentStatus();
  }, [animeId]); // Dependency on animeId ensures re-fetch when ID changes

  const addToList = async (listType: string) => {
    try {
      const access_token = getCookie('access_token');
      if (!access_token) {
        // Redirect to login or show login modal
        return;
      }
      
      await axios.post('https://aninew.ru/api/anime/list', {
        anime_id: animeId,
        poster: poster,
        title: title,
        list_type: listType,
        genres: genres
      }, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      setCurrentStatus(listType);
      setIsOpen(false);
    } catch (error) {
      console.error('Error adding to list:', error);
    }
  };

  const removeFromList = async () => {
    try {
      const access_token = getCookie('access_token');
      if (!access_token) return;
      
      // Здесь главное изменение: вместо отправки пустого list_type, 
      // мы отправляем DELETE запрос к специальному эндпоинту
      await axios.delete(`https://aninew.ru/api/anime/list/${animeId}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      
      setCurrentStatus(null);
      setIsOpen(false);
    } catch (error) {
      console.error('Error removing from list:', error);
    }
  };
  
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen]);

  const getStatusIcon = (status: string | null) => {
    if (!status) return <i className="fi fi-rr-plus"></i>;
    
    const iconMap: Record<string, string> = {
      watching: "fi fi-rr-play",
      favorites: "fi fi-rr-heart",
      planned: "fi fi-rr-bookmark",
      dropped: "fi fi-rr-cross-circle",
      completed: "fi fi-rr-check"
    };
    
    return <i className={iconMap[status] || "fi fi-rr-plus"}></i>;
  };

  return (
    <div className="list-action-button" ref={dropdownRef}>
      <button 
        onClick={() => setIsOpen(!isOpen)}
        className={currentStatus ? `list-btn-${currentStatus}` : ''}
      >
        {getStatusIcon(currentStatus)}
        <span>{currentStatus ? getStatusText(currentStatus) : 'Добавить в список'}</span>
      </button>
      
      {isOpen && (
        <div className="list-dropdown">
          <button onClick={() => addToList('watching')}>
            <i className="fi fi-rr-play"></i> Смотрю
          </button>
          <button onClick={() => addToList('favorites')}>
            <i className="fi fi-rr-heart"></i> Избранное
          </button>
          <button onClick={() => addToList('planned')}>
            <i className="fi fi-rr-bookmark"></i> Буду смотреть
          </button>
          <button onClick={() => addToList('completed')}>
            <i className="fi fi-rr-check"></i> Просмотрено
          </button>
          <button onClick={() => addToList('dropped')}>
            <i className="fi fi-rr-cross-circle"></i> Брошено
          </button>
          {currentStatus && (
            <button onClick={removeFromList} className="remove-list-btn">
              <i className="fi fi-rr-trash"></i> Удалить из списка
            </button>
          )}
        </div>
      )}
    </div>
  );
};

const getStatusText = (status: string): string => {
  const statusMap: Record<string, string> = {
    watching: 'Смотрю',
    favorites: 'В избранном',
    planned: 'В планах',
    dropped: 'Брошено',
    completed: 'Просмотрено'
  };
  return statusMap[status] || 'Добавить в список';
};

const AnimePage: React.FC = () => {
  const [anime, setAnime] = useState<AnimeData | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Загрузка информации об аниме...");
  const [selectedScreenshot, setSelectedScreenshot] = useState<string | null>(null);
  const [voices, setVoices] = useState<Voice[]>([]);
  const [selectedVoice, setSelectedVoice] = useState<Voice | null>(null);
  const { id } = useParams();
  const [selectedEpisode, setSelectedEpisode] = useState<number | null>(null);
  const [showScrollUp, setShowScrollUp] = useState(false);
  const [selectedMusic, setSelectedMusic] = useState<any>(null);

  // Обработка появления кнопки "Наверх" при прокрутке вниз
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowScrollUp(true);
      } else {
        setShowScrollUp(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setAnime(null);
    setLoading(true);
    setLoadingText("Загрузка информации об аниме...");
    setVoices([]);
    setSelectedVoice(null);
    setSelectedEpisode(null);
    
    // Also reset document title
    document.title = 'Загрузка аниме - AniNew';
  }, [id]);

  useEffect(() => {
    if (voices.length > 0) {
      const savedProgress = getAnimeProgress(id!);
      if (savedProgress) {
        const savedVoice = voices.find(v => v.name === savedProgress.voiceName);
        if (savedVoice) {
          setSelectedVoice(savedVoice);
          setSelectedEpisode(savedProgress.episode);
        } else {
          setSelectedVoice(voices[0]);
          setSelectedEpisode(1);
        }
      } else {
        setSelectedVoice(voices[0]);
        setSelectedEpisode(1);
      }
    }
  }, [voices, id]);

  useEffect(() => {
    document.title = anime ? `${anime.titles.russian} - AniNew` : 'Загрузка аниме - AniNew';
    document.body.classList.add('anime-page-body');
    
    return () => {
      document.body.classList.remove('anime-page-body');
    };
  }, [anime]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoadingText("Загрузка информации об аниме...");
        const animeResponse = await axios.get(`https://aninew.ru/api/anime/${id}`);
        setAnime(animeResponse.data);
        
        setLoadingText("Загрузка доступных озвучек...");
        const voicesResponse = await axios.get(`https://aninew.ru/api/anime/${id}/voices`);
        
        const filteredVoices = voicesResponse.data.voices
          .filter((voice: Voice) => voice.max_quality >= 720)
          .sort((a: Voice, b: Voice) => b.max_quality - a.max_quality);
        setVoices(filteredVoices);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoadingText("Ошибка загрузки данных. Пожалуйста, обновите страницу.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatRating = (rating: string) => {
    const ratingMap: { [key: string]: string } = {
      'pg_13': '13+',
      'r': '17+',
      'r_plus': '18+',
      'rx': '18+',
      'g': '0+',
      'pg': '7+'
    };
    return ratingMap[rating] || rating;
  };

  const translateStatus = (status: string) => {
    const statusMap: { [key: string]: string } = {
      'released': 'Вышло',
      'ongoing': 'Выходит',
      'anons': 'Анонс',
      'cancelled': 'Отменено'
    };
    return statusMap[status] || status;
  };

  const translateSeason = (season: string | null) => {
    if (!season) return 'Не указан';
    const [year, period] = season.split('_');
    const periodMap: { [key: string]: string } = {
      'winter': 'Зима',
      'spring': 'Весна',
      'summer': 'Лето',
      'fall': 'Осень'
    };
    return `${periodMap[period] || period} ${year}`;
  };

  const translateKind = (kind: string) => {
    const kindMap: { [key: string]: string } = {
      'tv': 'ТВ',
      'movie': 'Фильм',
      'ova': 'OVA',
      'ona': 'ONA',
      'special': 'Спешл',
      'music': 'Клип'
    };
    return kindMap[kind] || kind;
  };

  const goBack = () => {
    window.history.back();
  };

  const getAnimeProgress = (animeId: string): AnimeProgress | null => {
    const key = `anime_progress_${animeId}`;
    const saved = localStorage.getItem(key);
    return saved ? JSON.parse(saved) : null;
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="loading-animation">
          <div className="spinner"></div>
          <div className="spinner-ring"></div>
        </div>
        <div className="loading-text">{loadingText}</div>
      </div>
    );
  }

  return (
    <div className="anime-page">
      <div className="content-wrapper">
        <div className="page-header">
          <button className="back-button" onClick={goBack}>
            <i className="fi fi-rr-arrow-left"></i>
          </button>
          <h1 className="page-title">{anime?.titles.russian || 'Не найден титул'}</h1>
        </div>
        
        {anime && (
          <div className="main-content">
            <section className="section-main">
              <div className="anime-header">
                <div className="poster-wrapper">
                  <div className="poster-container">
                    <img 
                      src={anime.images.poster.original} 
                      alt={anime.titles.russian} 
                      className="poster" 
                    />
                    <div className="poster-overlay">
                      <div className="status-badge">{translateStatus(anime.info.status)}</div>
                      {anime.info.rating && (
                        <div className="rating-badge">{formatRating(anime.info.rating)}</div>
                      )}
                    </div>
                  </div>
                  
                  <div className="score-box">
                    <div className="score">
                      <span className="star">★</span>
                      <span>{anime.info.score.toFixed(1)}</span>
                    </div>
                    <div className="score-label">Оценка</div>
                  </div>
                  
                  <ListActionButton
                    animeId={anime.id}
                    poster={anime.images.poster.original}
                    title={anime.titles.russian}
                    genres={anime.genres}
                  />
                </div>
                
                <div className="info">
                  <div className="title-block">
                    <h1>{anime.titles.russian}</h1>
                    <p className="original-title">
                      {anime.titles.english || anime.titles.original}
                      {anime.titles.japanese && ` / ${anime.titles.japanese}`}
                    </p>
                  </div>
                  
                  <div className="meta-info">
                    <div className="details">
                      <div className="detail-item">
                        <span className="label">Тип</span>
                        <span className="value">{translateKind(anime.info.kind)}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Статус</span>
                        <span className="value">{translateStatus(anime.info.status)}</span>
                      </div>
                      <div className="detail-item">
                        <span className="label">Сезон</span>
                        <span className="value">{translateSeason(anime.info.season)}</span>
                      </div>
                      {anime.info.episodes.total > 0 && (
                        <div className="detail-item">
                          <span className="label">Эпизоды</span>
                          <span className="value">
                            {anime.info.episodes.aired}/{anime.info.episodes.total}
                          </span>
                        </div>
                      )}
                      {anime.info.episodes.duration && (
                        <div className="detail-item">
                          <span className="label">Длительность</span>
                          <span className="value">{anime.info.episodes.duration} мин</span>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div className="genres">
                    {anime.genres.map(genre => (
                      <span 
                        key={genre.id} 
                        className={`genre-tag ${genre.kind ? `genre-${genre.kind}` : ''}`}
                      >
                        {genre.russian}
                      </span>
                    ))}
                  </div>
                  
                  {anime.description && (
                    <div className="description">
                      <h3>Описание</h3>
                      <p>{anime.description}</p>
                    </div>
                  )}
                  
                  {anime.info.episodes.next_episode && (
                    <div className="next-episode">
                      <h3>Следующий эпизод</h3>
                      <time>
                        <i className="fi fi-rr-calendar"></i> {formatDate(anime.info.episodes.next_episode)}
                      </time>
                    </div>
                  )}
                </div>
                
                {anime.related && anime.related.length > 0 && (
                  <div className="related-section">
                    <h2>Связанные аниме</h2>
                    <div className="related-list">
                      {anime.related
                        .filter(related => related.id) // Фильтруем только записи с ID
                        .map(related => (
                          <Link
                            to={`/anime/${related.id}`}
                            key={related.id}
                            className="related-card"
                          >
                            <img 
                              src={related.poster} 
                              alt={related.name || "Связанное аниме"} 
                              loading="lazy" 
                            />
                            <div className="related-info">
                              <div className="relation-type">
                                {related.relation_text || 'Связанное'}
                              </div>
                              <h3>{related.name || 'Не найдено название'}</h3>
                            </div>
                          </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </section>
            
            <section className="section-player">
              <AnimePlayer
                animeId={id!}
                animeName={anime?.titles.russian || ''}
                voices={voices}
                selectedVoice={selectedVoice}
                selectedEpisode={selectedEpisode}
                totalEpisodes={anime.info.episodes.aired}
                onVoiceChange={setSelectedVoice}
                onEpisodeChange={setSelectedEpisode}
              />
            </section>
            
            <section className="section-additional">
              {anime.characters && anime.characters.length > 0 && (
                <div className="characters-section">
                  <h2>
                    <i className="fi fi-rr-user"></i> Персонажи
                  </h2>
                  <div className="characters-grid">
                    {anime.characters.map(character => (
                      <Link
                        to={`/chat/${character.id}`}
                        key={character.id}
                        className="character-card"
                      >
                        <div className="character-image">
                          <img
                            src={character.poster.original}
                            alt={character.russian || character.name}
                            loading="lazy"
                          />
                          <div className="character-overlay">
                            <span className="chat-icon">
                              <i className="fi fi-rr-comment-alt"></i>
                            </span>
                          </div>
                        </div>
                        <div className="character-info">
                          <h3>{character.russian || character.name}</h3>
                          <span className="role">{character.role}</span>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              )}
              
              {anime.images.screenshots && anime.images.screenshots.length > 0 && (
                <div className="screenshots-section">
                  <h2>
                    <i className="fi fi-rr-picture"></i> Скриншоты
                  </h2>
                  <div className="screenshots-slider">
                    {anime.images.screenshots.map((screenshot, index) => (
                      <div 
                        key={index}
                        className="screenshot-item"
                        onClick={() => setSelectedScreenshot(screenshot.original)}
                      >
                        <img
                          src={screenshot.preview}
                          alt={`Screenshot ${index + 1}`}
                          className="screenshot-preview"
                          loading="lazy"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              {anime.music && anime.music.length > 0 && (
                <div className="music-section">
                  <h2>
                    <i className="fi fi-rr-music-alt"></i> Музыка из аниме
                  </h2>
                  <div className="music-grid">
                    {anime.music
                      .sort((a, b) => {
                        // Функция для получения приоритета типа музыки
                        const getPriority = (type: string) => {
                          switch (type.toLowerCase()) {
                            case 'opening': return 1;
                            case 'ending': return 2;
                            default: return 3;
                          }
                        }
                        return getPriority(a.type) - getPriority(b.type);
                      })
                      .map((item, index) => (
                        <div 
                          key={index} 
                          className="music-card"
                          onClick={() => setSelectedMusic(item)}
                        >
                          <div className="music-thumbnail">
                            <img src={item.thumbnail} alt={item.title} />
                            <div className="play-overlay">
                              <i className="fi fi-rr-play"></i>
                            </div>
                            <div className="music-type-badge">{item.type}</div>
                          </div>
                          <div className="music-info">
                            <h3 className="music-title">{item.title}</h3>
                            <p className="music-artist">{item.artist}</p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </section>
          </div>
        )}
        
        {selectedScreenshot && (
          <div 
            className="screenshot-modal" 
            onClick={() => setSelectedScreenshot(null)}
          >
            <div className="modal-content">
              <img src={selectedScreenshot} alt="Full screenshot" />
              <button className="close-button">
                <i className="fi fi-rr-cross"></i>
              </button>
            </div>
          </div>
        )}
      </div>

      {showScrollUp && (
        <button
          className="scroll-top-button"
          onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          aria-label="Прокрутить наверх"
        >
          <i className="fi fi-rr-arrow-up"></i>
        </button>
      )}
      
      {selectedMusic && (
        <MusicPlayer
          isOpen={!!selectedMusic}
          onClose={() => setSelectedMusic(null)}
          trackData={selectedMusic}
        />
      )}
    </div>
  );
};

export default AnimePage;