import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPanel.scss';

interface AnimeData {
  anime_id: string;
  episode_number: string;
  voice_name: string;
  qualities: Array<{quality: string, file_id : string}>;
  links: Array<string>;
}

interface SearchResult {
  id: string;
  title: {
    russian: string;
    english: string;
  };
  poster: string;
}

const AdminPanel: React.FC = () => {
  const [animeData, setAnimeData] = useState<AnimeData>({
    anime_id: '',
    episode_number: '',
    voice_name: '',
    qualities: [],
    links: []
  });
  
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [selectedAnime, setSelectedAnime] = useState<SearchResult | null>(null);
  const [qualities, setQualities] = useState<string[]>(['480', '720', '1080','2160']);

  const searchAnime = async (query: string) => {
    try {
      const response = await axios.get(`https://aninew.ru/api/anime/search/${query}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching anime:', error);
    }
  };

  // Добавление качества
  const addQuality = () => {
    setAnimeData(prev => ({
      ...prev,
      qualities: [...prev.qualities, {quality: '', file_id: ''}]
    }));
  };

  // Обновление качества
  const updateQuality = (index: number, field: 'quality' | 'file_id', value: string) => {
    const newQualities = [...animeData.qualities];
    newQualities[index][field] = value;
    setAnimeData(prev => ({...prev, qualities: newQualities}));
  };

  const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await axios.post('https://aninew.ru/api/anime/provider/add', {
                anime_data: {
                    anime_id: animeData.anime_id,
                    episode_number: animeData.episode_number,
                    voice_name: animeData.voice_name,
                    qualities: animeData.qualities.map(q => ({
                        quality: q.quality,
                        file_id: q.file_id // Store file ID instead of direct URL
                    })),
                    links: animeData.links
                }
            });
            alert('Эпизод успешно добавлен');
        } catch (error) {
            console.error('Error:', error);
            alert('Ошибка при добавлении эпизода');
        }
    };

  return (
    <div className="admin-panel">
      <div className="search-section">
        <input
          type="text"
          placeholder="Поиск аниме..."
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            if (e.target.value.length > 2) {
              searchAnime(e.target.value);
            }
          }}
        />
        <div className="search-results">
          {searchResults.map(anime => (
            <div 
              key={anime.id}
              className="search-result-item"
              onClick={() => {
                setSelectedAnime(anime);
                setAnimeData(prev => ({...prev, anime_id: anime.id}));
              }}
            >
              <img src={anime.poster} alt={anime.title.russian} />
              <div>
                <h3>{anime.title.russian}</h3>
                <p>{anime.title.english}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedAnime && (
        <form onSubmit={handleSubmit} className="episode-form">
          <div className="form-group">
            <label>Номер эпизода</label>
            <input
              type="number"
              value={animeData.episode_number}
              onChange={(e) => setAnimeData({...animeData, episode_number: e.target.value})}
              required
            />
          </div>

          <div className="form-group">
            <label>Озвучка</label>
            <input
              type="text"
              value={animeData.voice_name}
              onChange={(e) => setAnimeData({...animeData, voice_name: e.target.value})}
              required
            />
          </div>

          <div className="qualities-section">
            <h3>Качество видео</h3>
            {animeData.qualities.map((q, index) => (
              <div key={index} className="quality-item">
                <select
                  value={q.quality}
                  onChange={(e) => updateQuality(index, 'quality', e.target.value)}
                >
                  {qualities.map(quality => (
                    <option key={quality} value={quality}>{quality}p</option>
                  ))}
                </select>
                <input
                  value={q.file_id}
                  onChange={(e) => updateQuality(index, 'file_id', e.target.value)}
                  placeholder="file_id"
                />
              </div>
            ))}
            <button type="button" onClick={addQuality}>
              Добавить качество
            </button>
          </div>

          <button type="submit" className="submit-button">
            Добавить эпизод
          </button>
        </form>
      )}
    </div>
  );
};

export default AdminPanel;