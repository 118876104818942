import React, { useState, useEffect, useRef, useMemo } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from 'axios';
import vjsqs from '@silvermine/videojs-quality-selector';
import '@silvermine/videojs-quality-selector/dist/css/quality-selector.css';
import { getCookie } from '../utils/auth';
import EpisodeDownloader from './EpisodeDownloader';

// ========================
// Data Types
// ========================
interface Voice {
  name: string;
  max_quality: number;
}

interface VideoLink {
  url: string;
  quality: number;
}

interface VideoLinks {
  direct: VideoLink[];
  kodik: string[];
}

interface VideoResponse {
  links: VideoLinks;
}

interface ProgressData {
  timestamp: number;
  quality: number;
  percentage: number;
  isCompleted: boolean;
}

interface AnimeData {
  selectedVoice?: string;
  lastEpisode?: number;
  progress?: {
    [episode: number]: ProgressData;
  }
}

// Player props
interface AnimePlayerProps {
  animeId: string;
  voices: Voice[];
  selectedVoice: Voice | null;
  selectedEpisode: number | null;
  totalEpisodes: number;
  animeName: string;
  onVoiceChange: (voice: Voice | null) => void;
  onEpisodeChange: (episode: number | null) => void;
}

// ========================
// localStorage Utility Functions
// ========================
const loadAnimeData = (animeId: string): AnimeData => {
  const dataStr = localStorage.getItem(`anime_data_${animeId}`);
  if (dataStr) {
    try {
      return JSON.parse(dataStr);
    } catch (e) {
      console.error('Error parsing anime_data:', e);
    }
  }
  return { progress: {} };
};

const saveAnimeData = (animeId: string, data: AnimeData) => {
  localStorage.setItem(`anime_data_${animeId}`, JSON.stringify(data));
};

// ========================
// API Functions
// ========================
const getAuthToken = () => {
  try {
    const token = getCookie('access_token');
    if (token) {
      return token;
    }
    console.log('Checking localStorage fallback...');
    const tokensStr = localStorage.getItem('auth_tokens');
    if (tokensStr) {
      const tokens = JSON.parse(tokensStr);
      return tokens.accessToken;
    }
  } catch (e) {
    console.error('Error getting auth token:', e);
  }
  return null;
};

const syncProgressWithServer = async (animeId: string, episode: number, data: ProgressData, voiceName: string) => {
  try {
    const token = getAuthToken();
    if (!token) {
      console.warn('No auth token found in storage');
      return;
    }

    console.log('Syncing progress with server:', {
      animeId,
      episode,
      data,
      voiceName
    });
    
    const response = await axios.post('/api/progress/sync', {
      animeId,
      episode,
      timestamp: data.timestamp,
      percentage: data.percentage,
      voiceName,
      quality: data.quality
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('Sync response:', response.data);
  } catch (error) {
    console.error('Error syncing progress with server:', error);
  }
};

// Добавляем функцию для синхронизации предпочтений
const syncPreferencesWithServer = async (animeId: string, voiceName: string, episode: number) => {
  try {
    const token = getAuthToken();
    if (!token) {
      console.warn('No auth token found in storage');
      return;
    }

    console.log('Syncing preferences with server:', {
      animeId,
      voiceName,
      episode
    });
    
    await axios.post('/api/progress/sync', {
      animeId,
      voiceName,
      episode,
      preferences: true // Флаг для обозначения, что это синхронизация предпочтений
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
  } catch (error) {
    console.error('Error syncing preferences with server:', error);
  }
};

const loadProgressFromServer = async (animeId: string) => {
  try {
    const token = getAuthToken();
    if (!token) {
      console.warn('No auth token found in storage');
      return null;
    }

    console.log('Loading progress from server for anime:', animeId);
    
    const response = await axios.get(`/api/progress/${animeId}`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    
    console.log('Server progress data:', response.data);
    
    // Создаем результат для прогресса эпизодов
    const episodesProgress = response.data.episodes?.reduce((acc: any, ep: any) => {
      acc[ep.episode] = {
        timestamp: ep.timestamp,
        quality: ep.quality,
        percentage: ep.percentage,
        isCompleted: ep.percentage >= 90
      };
      return acc;
    }, {}) || {};
    
    // Добавляем информацию о предпочтениях
    const result = {
      progress: episodesProgress,
      selectedVoice: response.data.preferences?.voice || null,
      lastEpisode: response.data.preferences?.episode || null
    };
    
    return result;
  } catch (error) {
    console.error('Error loading progress from server:', error);
    return null;
  }
};

// ========================
// Player Component
// ========================
const AnimePlayer: React.FC<AnimePlayerProps> = ({
  animeId,
  animeName,
  voices,
  selectedVoice,
  selectedEpisode,
  totalEpisodes,
  onVoiceChange,
  onEpisodeChange,
}) => {
  // State for voice and episode
  const [localVoice, setLocalVoice] = useState<Voice | null>(selectedVoice);
  const [localEpisode, setLocalEpisode] = useState<number | null>(selectedEpisode);
  const [animeData, setAnimeData] = useState<AnimeData>(() => loadAnimeData(animeId));
  
  // Video state
  const [videoLinks, setVideoLinks] = useState<VideoLinks | null>(null);
  const [selectedQuality, setSelectedQuality] = useState<number | null>(null);
  const [selectedSourceType, setSelectedSourceType] = useState<'direct' | 'kodik'>('direct');
  
  // Player refs and state
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const [playerInstance, setPlayerInstance] = useState<any>(null);
  const isInitialLoad = useRef(true);
  const lastFormattedSourcesRef = useRef<string>('');

  // ========================
  // Utility Functions
  // ========================
  const updateAnimeData = (updateFn: (data: AnimeData) => void) => {
    const data = loadAnimeData(animeId);
    updateFn(data);
    saveAnimeData(animeId, data);
    setAnimeData(data);
  };

  const updateAnimeProgress = (episode: number, time: number, quality: number, duration: number) => {
    updateAnimeData(data => {
      if (!data.progress) data.progress = {};
      const percentage = duration > 0 ? (time / duration) * 100 : 0;
      data.progress[episode] = {
        timestamp: time,
        quality: quality || 0,
        percentage,
        isCompleted: percentage >= 90,
      };
    });
  };

  const formatQuality = (quality: number): string => {
    switch (quality) {
      case 2160: return '4K';
      case 1080: return 'FullHD';
      default: return `${quality}p`;
    }
  };

  // Get video sources for the selected voice and episode
  const getVideoSource = async (voice: Voice, episode: number) => {
    try {
      const response = await axios.get<VideoResponse>(
        `https://aninew.ru/api/anime/${animeId}/voice/${voice.name}/episode/${episode}`
      );
      setVideoLinks(response.data.links);
      
      // Determine available qualities
      const allQualities = [
        ...response.data.links.direct.map(link => link.quality),
        ...response.data.links.kodik.map(link => parseInt(link.match(/(\d+)\.mp4/)?.[1] || '0'))
      ].filter(q => q > 0);
      
      // Sort and deduplicate qualities
      const uniqueQualities = Array.from(new Set(allQualities)).sort((a, b) => b - a);
      
      // Set initial quality if not already set
      if (!selectedQuality && uniqueQualities.length > 0) {
        setSelectedQuality(uniqueQualities[0]);
      }
    } catch (error) {
      console.error('Error fetching video:', error);
    }
  };

  // ========================
  // Initialization
  // ========================
  // Register quality selector plugin once
  useEffect(() => {
    vjsqs(videojs);
  }, []);

  // Load saved data on initial mount
  useEffect(() => {
    const initializeData = async () => {
      console.log('Initializing data for anime:', animeId);
      
      const localData = loadAnimeData(animeId);
      console.log('Local data:', localData);
      
      const serverData = await loadProgressFromServer(animeId);
      console.log('Server data:', serverData);
      
      // Определяем финальные данные
      let finalData = { ...localData };
      
      if (serverData) {
        // Объединяем прогресс эпизодов
        finalData.progress = {
          ...localData.progress || {},
          ...serverData.progress || {}
        };
        
        // Используем серверные предпочтения, если они есть
        if (serverData.lastEpisode) {
          finalData.lastEpisode = serverData.lastEpisode;
        }
        
        if (serverData.selectedVoice) {
          finalData.selectedVoice = serverData.selectedVoice;
        }
      }
      
      console.log('Final merged data:', finalData);
      
      setAnimeData(finalData);
      saveAnimeData(animeId, finalData);
      
      // Восстанавливаем выбор озвучки
      if (finalData.selectedVoice) {
        const foundVoice = voices.find(v => v.name === finalData.selectedVoice) || null;
        if (foundVoice) {
          setLocalVoice(foundVoice);
          onVoiceChange(foundVoice);
        }
      } else if (selectedVoice) {
        // Если нет сохраненной озвучки, но есть выбранная - сохраняем её на сервер
        updateAnimeData(data => {
          data.selectedVoice = selectedVoice.name;
        });
        if (getAuthToken()) {
          syncPreferencesWithServer(animeId, selectedVoice.name, finalData.lastEpisode || 1);
        }
      }
      
      // Восстанавливаем выбор эпизода
      if (finalData.lastEpisode != null) {
        setLocalEpisode(finalData.lastEpisode);
        onEpisodeChange(finalData.lastEpisode);
      } else if (selectedEpisode != null) {
        // Если нет сохраненного эпизода, но есть выбранный - сохраняем его на сервер
        updateAnimeData(data => {
          data.lastEpisode = selectedEpisode;
        });
        if (getAuthToken() && localVoice) {
          syncPreferencesWithServer(animeId, localVoice.name, selectedEpisode);
        }
      }
    };

    initializeData();
  }, [animeId, voices, onVoiceChange, onEpisodeChange]);

  // Sync currentEpisode with localEpisode
  const [currentEpisode, setCurrentEpisode] = useState<number | null>(localEpisode);
  useEffect(() => {
    setCurrentEpisode(localEpisode);
  }, [localEpisode]);

  // Initialize player
  useEffect(() => {
    if (!playerInstance && playerContainerRef.current) {
      const videoElement = document.createElement('video');
      videoElement.className = 'video-js vjs-default-skin';
      videoElement.controls = true;
      playerContainerRef.current.innerHTML = '';
      playerContainerRef.current.appendChild(videoElement);

      const player = videojs(videoElement, {
        fluid: true,
        autoplay: false,
        html5: {
          vhs: { overrideNative: true },
          nativeAudioTracks: false,
          nativeVideoTracks: false,
        },
        controls: true,
        playbackRates: [0.5, 1, 1.5, 2],
        controlBar: {
          children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'qualitySelector',
            'fullscreenToggle',
          ],
        },
        userActions: {
          doubleClick: true,
          hotkeys: {
            volumeStep: 0.1,
            seekStep: 5,
            enableNumbers: true,
            enableVolumeScroll: true,
          },
        },
      });

      player.on('keydown', (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === ' ') {
          if (player.paused()) {
            player.play();
          } else {
            player.pause();
          }
        }
      });

      setPlayerInstance(player);
    }
  }, []);

  // ========================
  // Handle Data Changes
  // ========================
  // Update animeData when voice changes
  useEffect(() => {
    if (localVoice) {
      updateAnimeData(data => {
        data.selectedVoice = localVoice.name;
      });
    }
  }, [localVoice]);

  // Update animeData when episode changes
  useEffect(() => {
    if (localEpisode != null) {
      updateAnimeData(data => {
        data.lastEpisode = localEpisode;
      });
    }
  }, [localEpisode]);

  // Reset video links and quality when voice changes
  useEffect(() => {
    if (localVoice) {
      setVideoLinks(null);
      setSelectedQuality(null);
      isInitialLoad.current = true;
    }
  }, [localVoice]);

  // Fetch video sources when voice or episode changes
  useEffect(() => {
    if (localVoice && currentEpisode != null) {
      getVideoSource(localVoice, currentEpisode);
    }
  }, [localVoice, currentEpisode]);

  // Auto-select source type based on available sources
  useEffect(() => {
    if (!videoLinks) return;
    
    const hasDirectSources = videoLinks.direct.length > 0;
    const hasKodikSources = videoLinks.kodik.length > 0;
    
    if (selectedSourceType === 'direct' && !hasDirectSources && hasKodikSources) {
      setSelectedSourceType('kodik');
    } else if (selectedSourceType === 'kodik' && !hasKodikSources && hasDirectSources) {
      setSelectedSourceType('direct');
    }
  }, [videoLinks, selectedSourceType]);

  // Set initial playback time based on saved progress
  const initialTime = useMemo(() => {
    if (localEpisode != null && animeData.progress && animeData.progress[localEpisode]) {
      return animeData.progress[localEpisode].timestamp;
    }
    return 0;
  }, [localEpisode, animeData]);

  // ========================
  // Player Source Management
  // ========================
  // Update player sources when video links or source type changes
  useEffect(() => {
    if (!playerInstance || !videoLinks) return;
    
    // Prepare sources based on selected type
    let sources = [];
    if (selectedSourceType === 'direct') {
      sources = videoLinks.direct.map(link => ({
        src: link.url,
        type: 'video/mp4',
        label: formatQuality(link.quality),
        quality: link.quality
      }));
    } else {
      sources = videoLinks.kodik.map(link => {
        const quality = parseInt(link.match(/(\d+)\.mp4/)?.[1] || '0');
        return {
          src: link.startsWith('http') ? link : `https:${link}`,
          type: 'application/x-mpegURL',
          label: formatQuality(quality),
          quality
        };
      });
    }
    
    // Sort sources by quality (highest first)
    sources.sort((a, b) => b.quality - a.quality);
    
    // If no sources available in selected type, try the other type
    if (sources.length === 0) {
      if (selectedSourceType === 'direct' && videoLinks.kodik.length > 0) {
        setSelectedSourceType('kodik');
        return;
      } else if (selectedSourceType === 'kodik' && videoLinks.direct.length > 0) {
        setSelectedSourceType('direct');
        return;
      }
    }
    
    if (sources.length === 0) return;
    
    // Create a sources signature to check if they've changed
    const sourcesSignature = sources.map(s => `${s.src}:${s.quality}`).join('|');
    if (sourcesSignature === lastFormattedSourcesRef.current) {
      return; // Skip if sources haven't changed
    }
    
    lastFormattedSourcesRef.current = sourcesSignature;
    
    // Get current quality or use highest available
    const currentQuality = selectedQuality || sources[0].quality;
    
    // Format sources for video.js with selected flag
    const formattedSources = sources.map(s => ({
      ...s,
      selected: s.quality === currentQuality
    }));
    
    // Apply sources to player
    const currentTime = playerInstance.currentTime();
    const wasPlaying = !playerInstance.paused();
    
    if (isInitialLoad.current) {
      playerInstance.src(formattedSources);
      playerInstance.one('loadedmetadata', () => {
        // Reset the quality selector
        const qs = playerInstance.controlBar.getChild('qualitySelector');
        if (qs) {
          playerInstance.controlBar.removeChild(qs);
        }
        playerInstance.controlBar.addChild('qualitySelector', {}, 3);
        
        // Set initial time and continue playing if needed
        playerInstance.currentTime(initialTime);
        if (wasPlaying) {
          playerInstance.play().catch((err: Error) => console.error('Playback error:', err));
        }
      });
      isInitialLoad.current = false;
    } else {
      playerInstance.pause();
      setTimeout(() => {
        playerInstance.src(formattedSources);
        playerInstance.one('loadedmetadata', () => {
          // Reset the quality selector
          const qs = playerInstance.controlBar.getChild('qualitySelector');
          if (qs) {
            playerInstance.controlBar.removeChild(qs);
          }
          playerInstance.controlBar.addChild('qualitySelector', {}, 3);
          
          // Restore playback state
          playerInstance.currentTime(currentTime);
          if (wasPlaying) {
            playerInstance.play().catch((err: Error) => console.error('Playback error:', err));
          }
        });
      }, 300);
    }
    
    // Set selected quality if not already set
    if (!selectedQuality && sources.length > 0) {
      setSelectedQuality(sources[0].quality);
    }
  }, [videoLinks, selectedSourceType, selectedQuality, initialTime, playerInstance]);

  // Handle quality change events from player
  useEffect(() => {
    if (!playerInstance) return;
    
    const handleQualityChange = (event: any) => {
      const newSource = event?.detail;
      if (!newSource || !newSource.quality) return;
      
      setSelectedQuality(newSource.quality);
    };
    
    playerInstance.on('QUALITY_REQUESTED', handleQualityChange);
    
    return () => {
      playerInstance.off('QUALITY_REQUESTED', handleQualityChange);
    };
  }, [playerInstance]);

  // ========================
  // Double Tap Feature
  // ========================
  useEffect(() => {
    if (!playerInstance) return;
    
    const videoElement = playerInstance.el();
    const seekTime = 10; // seconds for seek
    const doubleTapDelay = 300; // milliseconds between taps
    
    let lastTapTime = 0;
    let tapTimeout: ReturnType<typeof setTimeout> | null = null;
    
    // Function to determine screen side (left/right)
    const isRightSide = (x: number) => {
      const videoRect = videoElement.getBoundingClientRect();
      return x > videoRect.left + videoRect.width / 2;
    };
    
    // Show seek indicator
    const showSeekIndicator = (direction: 'forward' | 'backward') => {
      // Create temporary indicator element
      const indicator = document.createElement('div');
      indicator.className = `vjs-seek-indicator ${direction === 'forward' ? 'forward' : 'backward'}`;
      indicator.style.position = 'absolute';
      indicator.style.top = '50%';
      indicator.style.transform = 'translateY(-50%)';
      indicator.style.zIndex = '2';
      indicator.style.backgroundColor = 'rgba(255, 255, 255, 0.3)';
      indicator.style.borderRadius = '50%';
      indicator.style.width = '80px';
      indicator.style.height = '80px';
      indicator.style.display = 'flex';
      indicator.style.alignItems = 'center';
      indicator.style.justifyContent = 'center';
      
      // Position on right or left
      if (direction === 'forward') {
        indicator.style.right = '20%';
        indicator.innerHTML = `<span style="color: white; font-size: 24px;">+${seekTime}s</span>`;
      } else {
        indicator.style.left = '20%';
        indicator.innerHTML = `<span style="color: white; font-size: 24px;">-${seekTime}s</span>`;
      }
      
      videoElement.appendChild(indicator);
      
      // Remove indicator after 500ms
      setTimeout(() => {
        if (videoElement.contains(indicator)) {
          videoElement.removeChild(indicator);
        }
      }, 500);
    };
    
    // Double tap handler
    const handleDoubleTap = (e: TouchEvent | MouseEvent) => {
      // Get tap coordinates
      const x = 'touches' in e ? e.touches[0].clientX : (e as MouseEvent).clientX;
      
      const currentTime = Date.now();
      const timeDiff = currentTime - lastTapTime;
      
      // Check if this was a double tap
      if (timeDiff < doubleTapDelay) {
        // This is a double tap
        if (tapTimeout) {
          clearTimeout(tapTimeout);
          tapTimeout = null;
        }
        
        // Determine seek direction
        const rightSide = isRightSide(x);
        const newTime = playerInstance.currentTime() + (rightSide ? seekTime : -seekTime);
        
        // Seek video
        playerInstance.currentTime(Math.max(0, Math.min(playerInstance.duration(), newTime)));
        
        // Show indicator
        showSeekIndicator(rightSide ? 'forward' : 'backward');
        
        // Prevent further event processing
        e.preventDefault();
      } else {
        // Set timeout to reset if second tap doesn't happen
        if (tapTimeout) clearTimeout(tapTimeout);
        tapTimeout = setTimeout(() => {
          tapTimeout = null;
        }, doubleTapDelay);
      }
      
      lastTapTime = currentTime;
    };
    
    // Add event handlers
    videoElement.addEventListener('touchstart', handleDoubleTap);
    videoElement.addEventListener('mousedown', handleDoubleTap);
    
    // Cleanup on unmount
    return () => {
      videoElement.removeEventListener('touchstart', handleDoubleTap);
      videoElement.removeEventListener('mousedown', handleDoubleTap);
      if (tapTimeout) clearTimeout(tapTimeout);
    };
  }, [playerInstance]);

  // ========================
  // Progress Tracking
  // ========================
  // Save viewing progress
  useEffect(() => {
    if (!playerInstance || currentEpisode == null || !localVoice) {
      console.log('Skipping progress tracking:', {
        hasPlayer: !!playerInstance,
        currentEpisode,
        localVoice
      });
      return;
    }

    let syncInterval: NodeJS.Timeout;
    
    const saveProgress = () => {
      // Проверяем, что видео воспроизводится и загружено
      if (playerInstance.paused() || !playerInstance.readyState()) {
        console.log('Video is paused or not ready, skipping progress save');
        return;
      }

      const currentTime = playerInstance.currentTime();
      const duration = playerInstance.duration();
      
      if (duration && !isNaN(duration) && duration > 0) {
        console.log('Saving progress:', {
          currentTime,
          duration,
          percentage: (currentTime / duration) * 100
        });
        
        const progress = {
          timestamp: currentTime,
          quality: selectedQuality || 0,
          percentage: (currentTime / duration) * 100,
          isCompleted: (currentTime / duration) * 100 >= 90
        };
        
        // Сохраняем локально
        updateAnimeProgress(currentEpisode, currentTime, selectedQuality || 0, duration);
        
        // Синхронизируем с сервером только если видео воспроизводится
        syncProgressWithServer(animeId, currentEpisode, progress, localVoice.name);
      } else {
        console.warn('Invalid duration:', duration);
      }
    };

    // Используем debounced версию для timeupdate
    const debouncedSaveProgress = (() => {
      let timeoutId: ReturnType<typeof setTimeout> | null = null;
      return () => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(saveProgress, 1000);
      };
    })();

    // Слушаем timeupdate для частых обновлений
    playerInstance.on('timeupdate', debouncedSaveProgress);
    
    // Устанавливаем интервал синхронизации каждые 10 секунд
    syncInterval = setInterval(() => {
      if (!playerInstance.paused()) {
        saveProgress();
      }
    }, 10000);

    return () => {
      playerInstance.off('timeupdate', debouncedSaveProgress);
      if (syncInterval) {
        clearInterval(syncInterval);
      }
    };
  }, [playerInstance, currentEpisode, selectedQuality, localVoice, animeId]);

  // Save data before page unload
  useEffect(() => {
    const saveOnUnload = () => {
      if (playerInstance && currentEpisode != null) {
        const currentTime = playerInstance.currentTime();
        const duration = playerInstance.duration();
        
        if (duration && !isNaN(duration) && duration > 0) {
          updateAnimeProgress(currentEpisode, currentTime, selectedQuality || 0, duration);
        }
      }
    };
    
    window.addEventListener('beforeunload', saveOnUnload);
    
    return () => {
      window.removeEventListener('beforeunload', saveOnUnload);
    };
  }, [playerInstance, currentEpisode, selectedQuality]);

  // ========================
  // Event Handlers
  // ========================
  const handleEpisodeChange = (episode: number) => {
    if (episode !== currentEpisode) {
      setCurrentEpisode(episode);
      setLocalEpisode(episode);
      onEpisodeChange(episode);
      isInitialLoad.current = true;
      
      // Сохраняем в локальное хранилище
      updateAnimeData(data => {
        data.lastEpisode = episode;
      });
      
      // Синхронизируем с сервером, если озвучка выбрана и пользователь авторизован
      if (localVoice && getAuthToken()) {
        syncPreferencesWithServer(animeId, localVoice.name, episode);
      }
    }
  };

  const handleVoiceChange = (voice: Voice) => {
    setLocalVoice(voice);
    onVoiceChange(voice);
    
    // Сохраняем в локальное хранилище
    updateAnimeData(data => {
      data.selectedVoice = voice.name;
    });
    
    // Синхронизируем с сервером, если эпизод выбран и пользователь авторизован
    if (currentEpisode != null && getAuthToken()) {
      syncPreferencesWithServer(animeId, voice.name, currentEpisode);
    }
  };

  const handleSourceTypeChange = (type: 'direct' | 'kodik') => {
    if (videoLinks && selectedSourceType !== type) {
      setSelectedSourceType(type);
      setSelectedQuality(null);
      isInitialLoad.current = true;
    }
  };

  // ========================
  // Render
  // ========================
  return (
    <div className="player-section">
      <div className="player-layout">
        {/* Voice list */}
        <div className="voice-list">
          {voices.map((voice, index) => (
            <button
              key={index}
              className={`voice-option ${localVoice?.name === voice.name ? 'active' : ''}`}
              onClick={() => handleVoiceChange(voice)}
            >
              <span 
                className="quality-badge" 
                data-quality={voice.max_quality} 
                data-quality-hd={voice.max_quality >= 720}
              >
                {formatQuality(voice.max_quality)}
              </span>
              <span className="voice-name" title={voice.name}>
                {voice.name}
              </span>
            </button>
          ))}
        </div>
        
        {/* Player */}
        <div className="player-container">
          {/* Source type toggle (only show if both source types are available) */}
          {videoLinks && videoLinks.direct.length > 0 && videoLinks.kodik.length > 0 && (
            <div className="source-toggle">
              <button 
                className={selectedSourceType === 'direct' ? 'active' : ''} 
                onClick={() => handleSourceTypeChange('direct')}
              >
                AniNew
              </button>
              <button 
                className={selectedSourceType === 'kodik' ? 'active' : ''} 
                onClick={() => handleSourceTypeChange('kodik')}
              >
                Kodik
              </button>
            </div>
          )}
          
          {/* Video player */}
          <div className="video-wrapper" ref={playerContainerRef}></div>
        </div>
        
        {/* Episodes list with progress */}
        <div className="episodes-list">
          {Array.from({ length: totalEpisodes }, (_, i) => i + 1).map(episode => {
            const epProgress = animeData.progress?.[episode];
            return (
              <button
                key={episode}
                className={`episode-option ${currentEpisode === episode ? 'active' : ''} ${
                  epProgress?.isCompleted ? 'completed' : ''
                }`}
                onClick={() => handleEpisodeChange(episode)}
              >
                <div className="episode-header">
                  <span className="episode-number">Эпизод {episode}</span>
                  <span className={`episode-status ${
                    epProgress?.isCompleted ? 'completed' : 'not-watched'
                  }`}></span>
                </div>
                <div className="episode-progress">
                  <div 
                    className="progress-bar" 
                    style={{ width: `${epProgress?.percentage || 0}%` }} 
                  />
                  <span className="progress-text">
                    {epProgress ? `${Math.round(epProgress.percentage)}%` : '0%'}
                  </span>
                </div>
              </button>
            );
          })}
        </div>
      </div>
      {videoLinks && currentEpisode && (
        <EpisodeDownloader
          videoLinks={videoLinks}
          currentEpisode={currentEpisode}
          animeName={animeId}
        />
      )}
    </div>
  );
};

export default AnimePlayer;
