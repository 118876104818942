
// src/components/Snow/Snow.tsx
import React from 'react';
import './Snow.scss';

const Snow: React.FC = () => {
  return (
    <div className="snowflakes" aria-hidden="true">
      {[...Array(10)].map((_, i) => (
        <div key={i} className="snowflake">❅</div>
      ))}
    </div>
  );
};

export default Snow;