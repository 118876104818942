import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './UploadPage.scss';

interface VideoLinksResponse {
    direct: {
        quality: number;
        href: string;
    }[];
    kodik: string[];
}

interface AnimeTitle {
    original: string;
    russian: string;
    english: string;
    japanese: string;
}

interface Anime {
    id: string;
    title: AnimeTitle;
    poster: string;
    episodes: {
        total: number;
        aired: number;
    };
}

interface AnimeVoice {
    name: string;
    max_quality: number;
}

interface VoicesResponse {
    voices: AnimeVoice[];
    execution_time: number;
}

const BASE_VIDEO_URL = 'https://video1.anilib.me/.%D0%B0s/';

const UploadPage = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Anime[]>([]);
    const [selectedAnime, setSelectedAnime] = useState<Anime | null>(null);
    const [selectedVoiceId, setSelectedVoiceId] = useState<string>('');
    const [selectedVoice, setSelectedVoice] = useState<string>('');
    const [selectedEpisode, setSelectedEpisode] = useState<number>(1);
    const [availableLinks, setAvailableLinks] = useState<VideoLinksResponse | null>(null);
    const [selectedQuality, setSelectedQuality] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [availableVoices, setAvailableVoices] = useState<AnimeVoice[]>([]);

    const [selectedQualities, setSelectedQualities] = useState<number[]>([]);

    const getFullVideoUrl = (href: string): string => {
        return BASE_VIDEO_URL + href.replace(/^\//, '');
    };

    const searchAnime = async () => {
        if (searchTerm.length < 3) {
            alert('Введите минимум 3 символа');
            return;
        }
        try {
            const response = await axios.get(`/api/anime/search/${searchTerm}`);
            setSearchResults(response.data);
        } catch (error) {
            alert('Ошибка при поиске');
        }
    };

    const handleSelectAnime = async (anime: Anime) => {
        setSelectedAnime(anime);
        setSearchResults([]);
        setSearchTerm('');
        setSelectedVoiceId('');
        setSelectedVoice('');
        setAvailableLinks(null);
        
        try {
            const response = await axios.get<VoicesResponse>(`/api/anime/${anime.id}/voices`);
            setAvailableVoices(response.data.voices);
        } catch (error) {
            console.error('Ошибка при загрузке озвучек:', error);
            alert('Не удалось загрузить список озвучек');
        }
    };

    const fetchEpisodeLinks = async () => {
        if (!selectedAnime || !selectedVoiceId || !selectedEpisode) return;

        try {
            const response = await axios.get<VideoLinksResponse>(
                `/api/get_mangalib_links/${selectedAnime.id}/voice/${selectedVoiceId}/episode/${selectedEpisode}`
            );
            setAvailableLinks(response.data);
            
            if (response.data.direct.length > 0) {
                // Выбираем максимальное качество по умолчанию
                const maxQuality = Math.max(...response.data.direct.map(link => link.quality));
                setSelectedQuality(maxQuality);
            }
        } catch (error) {
            console.error('Ошибка при загрузке ссылок:', error);
            alert('Не удалось загрузить ссылки на видео');
        }
    };

    useEffect(() => {
        if (selectedVoiceId && selectedEpisode) {
            fetchEpisodeLinks();
        }
    }, [selectedVoiceId, selectedEpisode]);

    const handleUpload = async () => {
        if (!selectedAnime || !selectedVoice || !availableLinks || selectedQualities.length === 0) {
            alert('Пожалуйста, выберите хотя бы одно качество');
            return;
        }
    
        setLoading(true);
        try {
            const requestBody = {
                shikimori_id: Number(selectedAnime.id),
                voice_name: selectedVoice,
                voice_id: Number(selectedVoiceId) || 0,
                episode: selectedEpisode,
                qualities: selectedQualities,
                links: availableLinks
            };
    
            const response = await axios.post('/api/anime/upload', requestBody);
            if (response.data.status === 'success') {
                alert('Успешно загружено!');
            }
        } catch (error) {
            console.error('Ошибка загрузки:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="upload-page">
            <h1>Загрузка аниме</h1>
            <div className="search-container">
                <div className="search-section">
                    <input 
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Поиск аниме..."
                    />
                    <button onClick={searchAnime}>Поиск</button>
                </div>
                {searchResults.length > 0 && (
                    <div className="search-results">
                        {searchResults.map(anime => (
                            <div 
                                key={anime.id} 
                                className="search-result-item"
                                onClick={() => handleSelectAnime(anime)}
                            >
                                <img src={anime.poster} alt={anime.title.russian} />
                                <div className="anime-info">
                                    <h3>{anime.title.russian}</h3>
                                    <p>{anime.title.original}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {selectedAnime && (
                <div className="upload-form">
                    <div className="selected-anime">
                        <img src={selectedAnime.poster} alt={selectedAnime.title.russian} />
                        <div className="anime-details">
                            <h2>{selectedAnime.title.russian}</h2>
                            <p>{selectedAnime.title.original}</p>
                        </div>
                    </div>

                    <div className="form-controls">
                        <div className="input-group">
                            <label>Озвучка:</label>
                            <select
                                value={selectedVoiceId}
                                onChange={(e) => {
                                    setSelectedVoice(e.target.value);
                                    setSelectedVoiceId(e.target.value);
                                }}
                            >
                                <option value="">Выберите озвучку</option>
                                {availableVoices.map(voice => (
                                    <option key={voice.name} value={voice.name}>
                                        {voice.name} (макс. {voice.max_quality}p)
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="input-group">
                            <label>Эпизод:</label>
                            <input
                                type="number"
                                value={selectedEpisode}
                                onChange={(e) => setSelectedEpisode(Number(e.target.value))}
                                min="1"
                                max={selectedAnime.episodes.total || 999}
                            />
                        </div>
                        {availableLinks?.direct && availableLinks.direct.length > 0 && (
                            <div className="input-group">
                                <label>Доступные качества:</label>
                                <div className="quality-links">
                                    {availableLinks.direct.map(link => (
                                        <div key={link.quality} className="quality-option">
                                            <input
                                                type="checkbox"
                                                id={`quality-${link.quality}`}
                                                value={link.quality}
                                                checked={selectedQualities.includes(link.quality)}
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedQualities([...selectedQualities, link.quality]);
                                                    } else {
                                                        setSelectedQualities(selectedQualities.filter(q => q !== link.quality));
                                                    }
                                                }}
                                            />
                                            <label htmlFor={`quality-${link.quality}`}>
                                                {link.quality}p
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}

                        <button 
                            className="upload-button"
                            onClick={handleUpload}
                            disabled={loading || !selectedVoiceId || !selectedQuality}
                        >
                            {loading ? 'Загрузка...' : 'Загрузить'}
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UploadPage;