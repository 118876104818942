import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

const root = createRoot(document.getElementById('root') as HTMLElement);

if (process.env.NODE_ENV !== "production") {
  import("hide-cra-error-overlay").then(({ initHideOverlay }) =>
    initHideOverlay({
      overlayId: [
        "webpack-dev-server-client-overlay",
        "webpack-dev-server-client-overlay-div",
      ],
      withRestoreButton: false, // отключаем кнопку «Show Errors»
      disable: false,
    })
  );
}

root.render(
  <React.StrictMode>
      <App />
  </React.StrictMode>
);