// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snowflakes {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
}

.snowflake {
  position: fixed;
  color: #fff;
  font-size: 1em;
  text-shadow: 0 0 5px #000;
  animation: snowfall linear infinite;
}
.snowflake:nth-child(1) {
  left: 36%;
  animation-duration: 7s;
  animation-delay: -1s;
}
.snowflake:nth-child(2) {
  left: 17%;
  animation-duration: 7s;
  animation-delay: -5s;
}
.snowflake:nth-child(3) {
  left: 95%;
  animation-duration: 15s;
  animation-delay: -6s;
}
.snowflake:nth-child(4) {
  left: 60%;
  animation-duration: 13s;
  animation-delay: -2s;
}
.snowflake:nth-child(5) {
  left: 68%;
  animation-duration: 13s;
  animation-delay: -6s;
}
.snowflake:nth-child(6) {
  left: 51%;
  animation-duration: 11s;
  animation-delay: -5s;
}
.snowflake:nth-child(7) {
  left: 43%;
  animation-duration: 15s;
  animation-delay: -2s;
}
.snowflake:nth-child(8) {
  left: 93%;
  animation-duration: 15s;
  animation-delay: -9s;
}
.snowflake:nth-child(9) {
  left: 67%;
  animation-duration: 9s;
  animation-delay: -10s;
}
.snowflake:nth-child(10) {
  left: 44%;
  animation-duration: 10s;
  animation-delay: -1s;
}

@keyframes snowfall {
  0% {
    transform: translateY(-100vh);
  }
  100% {
    transform: translateY(100vh);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Snow/Snow.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,oBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,WAAA;EACA,cAAA;EACA,yBAAA;EACA,mCAAA;AAAJ;AAGM;EACE,SAAA;EACA,sBAAA;EACA,oBAAA;AADR;AAFM;EACE,SAAA;EACA,sBAAA;EACA,oBAAA;AAIR;AAPM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AASR;AAZM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AAcR;AAjBM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AAmBR;AAtBM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AAwBR;AA3BM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AA6BR;AAhCM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AAkCR;AArCM;EACE,SAAA;EACA,sBAAA;EACA,qBAAA;AAuCR;AA1CM;EACE,SAAA;EACA,uBAAA;EACA,oBAAA;AA4CR;;AAvCE;EACE;IACE,6BAAA;EA0CJ;EAxCE;IACE,4BAAA;EA0CJ;AACF","sourcesContent":["// src/components/Snow/Snow.scss\r\n.snowflakes {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100%;\r\n    z-index: 1;\r\n    pointer-events: none;\r\n  }\r\n  \r\n  .snowflake {\r\n    position: fixed;\r\n    color: #fff;\r\n    font-size: 1em;\r\n    text-shadow: 0 0 5px #000;\r\n    animation: snowfall linear infinite;\r\n    \r\n    @for $i from 1 through 10 {\r\n      &:nth-child(#{$i}) {\r\n        left: random(100) * 1%;\r\n        animation-duration: (5 + random(10)) * 1s;\r\n        animation-delay: random(10) * -1s;\r\n      }\r\n    }\r\n  }\r\n  \r\n  @keyframes snowfall {\r\n    0% {\r\n      transform: translateY(-100vh);\r\n    }\r\n    100% {\r\n      transform: translateY(100vh);\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
