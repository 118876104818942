// src/components/AuthProvider.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkAndRefreshToken, getCookie } from '../utils/auth';
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://btotmorcpopjxetahrdv.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
if (!supabaseKey) {
    throw new Error('REACT_APP_SUPABASE_KEY is not defined');
}

const supabase = createClient(supabaseUrl, supabaseKey!);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = getCookie('access_token');
      const refreshToken = getCookie('refresh_token');
      console.log('Checking auth tokens:', { accessToken, refreshToken });
      
      if (refreshToken) {
        try {
          const result = await checkAndRefreshToken(supabase);
          console.log('Token refresh result:', result);
        } catch (error) {
          console.error('Auth error:', error);
        }
      }
    };
  
    checkAuth();
  }, [navigate]);

  return <>{children}</>;
};