import React, { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { useNavigate } from 'react-router-dom';
import './AuthPage.scss';

const supabaseUrl = 'https://btotmorcpopjxetahrdv.supabase.co';
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY;
if (!supabaseKey) {
    throw new Error('REACT_APP_SUPABASE_KEY is not defined');
}

import { saveAuthTokens } from '../utils/auth';

const supabase = createClient(supabaseUrl, supabaseKey!);

const AuthPage: React.FC = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [message, setMessage] = useState('');
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const navigate = useNavigate();

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    try {
      if (isLogin) {
        const { data, error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });
        if (error) throw error;
        localStorage.setItem('user', JSON.stringify(data.user));
        saveAuthTokens({
            access_token: data.session.access_token,
            refresh_token: data.session.refresh_token,
            user: data.user
          });
        navigate('/main');
      } else {
        const { data, error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            data: { username },
          },
        });
        if (error) throw error;
        if (data.user?.identities?.length === 0) {
          setMessage('Пользователь с таким email уже существует.');
        } else {
          setMessage('Пожалуйста, подтвердите вашу почту. Проверьте ваш почтовый ящик.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('Произошла неизвестная ошибка');
      }
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(resetEmail);
      if (error) throw error;
      setMessage('Инструкции по сбросу пароля отправлены на ваш email.');
      setShowResetPassword(false);
    } catch (error) {
      console.error('Error:', error);
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('Произошла ошибка при отправке инструкций по сбросу пароля.');
      }
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>{isLogin ? 'Вход' : 'Регистрация'}</h2>
        {message && <div className="message">{message}</div>}
        <form onSubmit={handleAuth}>
          {!isLogin && (
            <input
              type="text"
              placeholder="Имя пользователя"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          )}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit" className="submit-btn">
            {isLogin ? 'Войти' : 'Зарегистрироваться'}
          </button>
        </form>
        <p>
          {isLogin ? 'Нет аккаунта?' : 'Уже есть аккаунт?'}
          <button className="switch-auth" onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? 'Зарегистрироваться' : 'Войти'}
          </button>
        </p>
        {isLogin && (
          <button className="forgot-password" onClick={() => setShowResetPassword(true)}>
            Забыли пароль?
          </button>
        )}
      </div>
      {showResetPassword && (
        <div className="reset-password-modal">
          <div className="modal-content">
            <h3>Сброс пароля</h3>
            <form onSubmit={handleResetPassword}>
              <input
                type="email"
                placeholder="Введите ваш email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                required
              />
              <button type="submit">Отправить инструкции</button>
            </form>
            <button onClick={() => setShowResetPassword(false)}>Закрыть</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthPage;