import React, { useEffect, useState, useRef } from 'react';
import './MusicPlayer.scss';

interface MusicTrack {
  title: string;
  artist: string;
  thumbnail: string;
  type: string;
  musikId?: string;
}

interface MusicPlayerProps {
  isOpen: boolean;
  onClose: () => void;
  trackData: MusicTrack;
}

const MusicPlayer: React.FC<MusicPlayerProps> = ({ isOpen, onClose, trackData }) => {
  const [loading, setLoading] = useState(true);
  const [loadingStage, setLoadingStage] = useState(0); // 0: поиск ID, 1: загрузка трека, 2: готов
  const [trackUrl, setTrackUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false); // Для сохранения состояния воспроизведения
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [allTracks, setAllTracks] = useState<MusicTrack[]>([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const [showPlaylist, setShowPlaylist] = useState(false); // Состояние для показа/скрытия плейлиста
  
  // Забавные сообщения при загрузке
  const searchMessages = [
    "Ищем трек по всему миру...",
    "Наш сервер уже ищет по всей Японии, какой отаку сохранил этот трек...",
    "Отправляем дронов на поиски аудиофайлов...",
    "Проникаем в тайные музыкальные архивы...",
    "Спрашиваем у Хикикомори, где найти этот трек...",
    "Ищем в шкафу у композитора...",
    "Поднимаем гору Фудзи в поисках звуков...",
    "Просим котиков с клавиатурами помочь нам...",
  ];
  
  const downloadMessages = [
    "Готовим музыку к прослушиванию...",
    "Настраиваем частоты для идеального звучания...",
    "Собираем ноты воедино...",
    "Обучаем пикселей красиво танцевать...",
    "Полируем аудиофайл до блеска...",
    "Заправляем трек особым соусом...",
    "Вдохновляем битрейт на подвиги...",
    "Делаем так, чтобы звук ласкал ваши уши...",
  ];
  
  // Эффект для смены сообщений загрузки
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        const messages = loadingStage === 0 ? searchMessages : downloadMessages;
        setLoadingMessageIndex((prev) => (prev + 1) % messages.length);
      }, 2500);
      
      return () => clearInterval(interval);
    }
  }, [loading, loadingStage]);

  useEffect(() => {
    if (isOpen && trackData) {
      fetchMusicData();
    }
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
    };
  }, [isOpen, trackData]);

  const fetchMusicData = async () => {
    try {
      setLoading(true);
      setLoadingStage(0);
      setError(null);
      setWasPlaying(isPlaying); // Сохраняем текущее состояние воспроизведения
      
      if (audioRef.current) {
        audioRef.current.pause();
      }
      
      // Получение ID трека, если его нет
      if (!trackData.musikId) {
        const response = await fetch(`/api/anime/music-ids?title=${encodeURIComponent(trackData.title)}&artist=${encodeURIComponent(trackData.artist)}`);
        const data = await response.json();
        
        if (data.track_info && data.track_info.length > 0) {
          // Устанавливаем все треки
          const tracks = data.track_info.map((track: any) => ({
            title: track.title,
            artist: Array.isArray(track.artists) ? track.artists.join(', ') : track.artists,
            thumbnail: trackData.thumbnail,
            type: trackData.type,
            musikId: track.musikid
          }));
          
          setAllTracks(tracks);
          setCurrentTrackIndex(0);
          
          // Загружаем первый трек
          await loadTrack(tracks[0].musikId);
        } else {
          setError('Не удалось найти музыку');
          setLoading(false);
        }
      } else {
        // Если есть только один трек
        setAllTracks([trackData]);
        setCurrentTrackIndex(0);
        await loadTrack(trackData.musikId);
      }
    } catch (error) {
      console.error('Ошибка при получении музыкальных данных:', error);
      setError('Произошла ошибка при загрузке музыки');
      setLoading(false);
    }
  };

  const loadTrack = async (musikId: string) => {
    try {
      setLoadingStage(1);
      setError(null);
      setWasPlaying(isPlaying); // Сохраняем состояние воспроизведения
      
      if (audioRef.current) {
        audioRef.current.pause();
      }
      
      const response = await fetch(`/api/anime/music/link?musik_id=${encodeURIComponent(musikId)}`);
      const data = await response.json();
      
      if (data.download_url) {
        setTrackUrl(data.download_url);
        setLoading(false);
        
        // Автоматически запускаем воспроизведение после загрузки трека
        setTimeout(() => {
          if (audioRef.current) {
            audioRef.current.currentTime = 0;
            audioRef.current.play()
              .then(() => setIsPlaying(true))
              .catch(err => {
                console.error('Ошибка автовоспроизведения:', err);
                setIsPlaying(false);
              });
          }
        }, 300); // Небольшая задержка для полной загрузки аудио
      } else {
        setError(data.error || 'Не удалось получить ссылку на трек');
        setLoading(false);
      }
    } catch (error) {
      console.error('Ошибка при загрузке трека:', error);
      setError('Произошла ошибка при загрузке трека');
      setLoading(false);
    }
  };

  const handlePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const current = audioRef.current.currentTime;
      const duration = audioRef.current.duration;
      
      setCurrentTime(current);
      
      // Расчет процента прогресса (0-100)
      if (duration > 0) {
        const percentage = (current / duration) * 100;
        setProgress(percentage);
      }
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.target.value);
    setProgress(value);
    
    if (audioRef.current && audioRef.current.duration) {
      const newTime = (value / 100) * audioRef.current.duration;
      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const formatTime = (seconds: number): string => {
    if (isNaN(seconds)) return '0:00';
    
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const getLoadingText = () => {
    const messages = loadingStage === 0 ? searchMessages : downloadMessages;
    return messages[loadingMessageIndex];
  };

  const changeTrack = (direction: 'prev' | 'next') => {
    if (allTracks.length <= 1) return;
    
    let newIndex = currentTrackIndex;
    
    if (direction === 'prev') {
      newIndex = (currentTrackIndex - 1 + allTracks.length) % allTracks.length;
    } else {
      newIndex = (currentTrackIndex + 1) % allTracks.length;
    }
    
    setCurrentTrackIndex(newIndex);
    setLoading(true);
    loadTrack(allTracks[newIndex].musikId!);
  };

  // Заменяем функцию скачивания на новую, работающую версию
  const downloadTrack = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!trackUrl) return;
    
    try {
      // Показываем уведомление о начале загрузки
      setError("Начинаем загрузку файла...");
      
      // Получаем файл через fetch
      const response = await fetch(trackUrl);
      
      // Проверяем успешность запроса
      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      
      // Преобразуем ответ в blob
      const blob = await response.blob();
      
      // Создаем объект URL из blob
      const blobUrl = URL.createObjectURL(blob);
      
      // Создаем скрытый iframe для загрузки
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      
      // Создаем документ внутри iframe
      const iframeDoc = iframe.contentWindow?.document;
      if (!iframeDoc) {
        throw new Error("Не удалось создать iframe");
      }
      
      // Создаем элемент для загрузки внутри iframe
      const downloadElement = iframeDoc.createElement('a');
      const currentTrack = allTracks[currentTrackIndex];
      const fileName = `${currentTrack.artist} - ${currentTrack.title}.mp3`;
      
      // Настраиваем элемент для скачивания
      downloadElement.href = blobUrl;
      downloadElement.download = fileName;
      
      // Добавляем элемент в документ iframe и вызываем клик
      iframeDoc.body.appendChild(downloadElement);
      downloadElement.click();
      
      // Удаляем iframe после короткой задержки
      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(iframe);
        setError(null);
      }, 1000);
      
    } catch (error) {
      console.error("Ошибка при скачивании:", error);
      setError(`Ошибка скачивания: ${error instanceof Error ? error.message : "Неизвестная ошибка"}`);
      
      // Сбрасываем ошибку через некоторое время
      setTimeout(() => setError(null), 3000);
    }
  };

  // Текущий активный трек
  const currentTrack = allTracks[currentTrackIndex] || trackData;

  // Функция для переключения отображения плейлиста на мобильных устройствах
  const togglePlaylist = () => {
    setShowPlaylist(!showPlaylist);
  };

  return (
    <div className="music-player-overlay" onClick={onClose}>
      <div className="music-player" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <i className="fi fi-rr-cross"></i>
        </button>
        
        <div className="player-container">
          <div className="player-main">
            <div className={`album-cover ${isPlaying && !loading ? 'rotating' : ''}`}>
              <img src={currentTrack.thumbnail} alt={currentTrack.title} />
              {loading && (
                <div className="loading-overlay">
                  <div className="spinner"></div>
                  <p className="loading-text">{getLoadingText()}</p>
                </div>
              )}
            </div>
            
            <div className="track-info">
              <div className="track-type-badge">{currentTrack.type}</div>
              <h2 className="track-title">{currentTrack.title}</h2>
              <p className="track-artist">{currentTrack.artist}</p>
              
              {error && <p className="error-message">{error}</p>}
              
              {trackUrl && !loading && (
                <>
                  <div className="progress-container">
                    <span className="time current">{formatTime(currentTime)}</span>
                    <input
                      type="range"
                      min="0"
                      max="100"
                      value={progress}
                      onChange={handleSeek}
                      className="progress-bar"
                    />
                    <span className="time duration">{formatTime(duration)}</span>
                  </div>
                  
                  <div className="controls">
                    <button 
                      className={`control-button ${allTracks.length > 1 ? '' : 'disabled'}`}
                      onClick={() => allTracks.length > 1 && changeTrack('prev')}
                      disabled={allTracks.length <= 1}
                    >
                      <i className="fi fi-rr-rewind"></i>
                    </button>
                    <button className="play-button" onClick={handlePlayPause}>
                      <i className={`fi ${isPlaying ? 'fi-rr-pause' : 'fi-rr-play'}`}></i>
                    </button>
                    <button 
                      className={`control-button ${allTracks.length > 1 ? '' : 'disabled'}`}
                      onClick={() => allTracks.length > 1 && changeTrack('next')}
                      disabled={allTracks.length <= 1}
                    >
                      <i className="fi fi-rr-forward"></i>
                    </button>
                    
                    {/* Кнопка скачивания */}
                    {trackUrl && (
                      <button 
                        onClick={downloadTrack}
                        className="download-button"
                      >
                        <i className="fi fi-rr-download"></i>
                      </button>
                    )}
                    
                    {/* Кнопка переключения отображения плейлиста для мобильных */}
                    {allTracks.length > 1 && (
                      <button 
                        onClick={togglePlaylist}
                        className="playlist-toggle-button"
                      >
                        <i className={`fi ${showPlaylist ? 'fi-rr-list-check' : 'fi-rr-list'}`}></i>
                      </button>
                    )}
                  </div>
                  
                  <audio
                    ref={audioRef}
                    src={trackUrl}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={() => allTracks.length > 1 ? changeTrack('next') : setIsPlaying(false)}
                  />
                </>
              )}
            </div>
          </div>
          
          {/* Список треков справа - добавляем класс для мобильной версии */}
          {allTracks.length > 1 && (
            <div className={`playlist-container ${showPlaylist ? 'show-mobile' : ''}`}>
              <div className="playlist-header">
                <h3 className="playlist-title">Список треков</h3>
                <button className="close-playlist-button" onClick={togglePlaylist}>
                  <i className="fi fi-rr-cross-small"></i>
                </button>
              </div>
              <div className="playlist">
                {allTracks.map((track, index) => (
                  <div 
                    key={index}
                    className={`playlist-item ${index === currentTrackIndex ? 'active' : ''}`}
                    onClick={() => {
                      setCurrentTrackIndex(index);
                      setLoading(true);
                      loadTrack(track.musikId!);
                      // Скрываем плейлист после выбора трека на мобильных
                      if (window.innerWidth <= 768) {
                        setShowPlaylist(false);
                      }
                    }}
                  >
                    <div className="track-number">{index + 1}</div>
                    <div className="track-details">
                      <div className="item-title">{track.title}</div>
                      <div className="item-artist">{track.artist}</div>
                    </div>
                    {index === currentTrackIndex && isPlaying && (
                      <div className="now-playing">
                        <div className="bar"></div>
                        <div className="bar"></div>
                        <div className="bar"></div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MusicPlayer; 