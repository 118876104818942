import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Snow from '../components/Snow/Snow';
import './HomePage.scss';
import "@flaticon/flaticon-uicons/css/all/all.css";
import MusicPlayer from '../components/MusicPlayer';

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('benefits');
  const heroRef = useRef<HTMLDivElement>(null);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [showMusicPlayer, setShowMusicPlayer] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState({
    title: "Акебоши - Ветер",
    artist: "Naruto OST",
    thumbnail: "https://cdn.aniplaylist.com/thumbnails/hEJGZNQzXa3agFht86YWGRMoYyNHoOyDxWgYgiqY.jpg",
    type: "OST",
    musikId: "DCI+XlA0OAksJyQ="
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          }
        });
      },
      { threshold: 0.1, rootMargin: "0px 0px -100px 0px" }
    );

    document.querySelectorAll('.animated-item').forEach(
      el => observer.observe(el)
    );

    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      observer.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Функция для анимации элементов при прокрутке
  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const benefitItems = [
    {
      icon: "fi fi-rr-book-alt",
      title: "Огромная библиотека",
      description: "Тысячи аниме-тайтлов в одном месте — от классики до новейших релизов сезона",
      accent: "Разнообразие контента и регулярные обновления"
    },
    {
      icon: "fi fi-rr-diamond",
      title: "Высокое качество",
      description: "Смотрите любимые аниме в HD качестве без задержек и буферизации",
      accent: "Качество видео и бесперебойный просмотр"
    },
    {
      icon: "fi fi-rr-cloud-back-up",
      title: "Синхронизация данных",
      description: "Продолжайте просмотр с того же места на любом устройстве — смартфоне, планшете или ПК",
      accent: "Бесшовный переход между устройствами и удобство использования"
    },
    {
      icon: "fi fi-rr-time-check",
      title: "Сохранение прогресса",
      description: "Автоматическое отслеживание просмотренных серий и создание персональной истории просмотров",
      accent: "Никогда не потеряете место, где остановились, даже в длинных сериалах"
    },
    {
      icon: "fi fi-rr-layout-fluid",
      title: "Элегантный дизайн",
      description: "Интуитивно понятный и стильный интерфейс в аниме-эстетике для комфортного просмотра",
      accent: "Визуальная привлекательность и удобство навигации"
    }
  ];

  const exclusiveItems = [
    {
      icon: "fi fi-rr-comment-alt",
      title: "Общение с любимыми персонажами",
      description: "Общайтесь с персонажами из ваших любимых аниме в интерактивных чатах с ИИ",
      accent: "Диалоги с героями аниме в их характерном стиле"
    },
    {
      icon: "fi fi-rr-chat-arrow-grow",
      title: "Уникальный опыт взаимодействия",
      description: "Персонажи отвечают как в аниме — с их характерными фразами, манерой речи и особенностями",
      accent: "Эмоциональные реакции, тематические диалоги"
    },
    {
      icon: "fi fi-rr-cloud-download",
      title: "Оффлайн-режим",
      description: "Скачивайте любимые аниме и наслаждайтесь просмотром даже без интернета",
      accent: "Доступность контента в любых условиях"
    },
    {
      icon: "fi fi-rr-music",
      title: "Музыкальная коллекция",
      description: "Слушайте и скачивайте опенинги, эндинги и саундтреки из любимых аниме в одном месте",
      accent: "Плейлисты по аниме, поиск по композиторам, возможность скачивания"
    },
    {
      icon: "fi fi-rr-info",
      title: "Расширенные знания вселенной",
      description: "Узнайте больше о мире аниме через естественный диалог с его обитателями",
      accent: "Информация о вселенной аниме через разговор с персонажами"
    }
  ];

  const upcomingItems = [
    {
      icon: "fi fi-rr-users",
      title: "Активное сообщество",
      description: "Общайтесь с единомышленниками на нашем форуме — обсуждайте новинки, делитесь впечатлениями и находите друзей",
      accent: "Тематические разделы, рейтинг пользователей, еженедельные дискуссии"
    },
    {
      icon: "fi fi-rr-magic-wand",
      title: "ИИ генерация аниме-артов",
      description: "Создавайте уникальные изображения в стиле аниме по текстовому описанию",
      accent: "Генерация персонажей, фонов, сцен из вашего воображения"
    },
    {
      icon: "fi fi-rr-bulb",
      title: "ИИ рекомендации",
      description: "Персонализированные рекомендации аниме на основе ваших предпочтений от искусственного интеллекта",
      accent: "Умный подбор по жанрам, настроению и вашей истории просмотров"
    },
    {
      icon: "fi fi-rr-shuffle",
      title: "Синхронизация с внешними сайтами",
      description: "Синхронизируйте свой прогресс просмотра с другими популярными аниме-платформами",
      accent: "Автоматический импорт и экспорт истории просмотров, отслеживание прогресса"
    },
    {
      icon: "fi fi-brands-telegram",
      title: "Телеграм-бот AniNew",
      description: "Смотрите аниме и получайте уведомления о новых сериях через удобного телеграм-бота",
      accent: "Мгновенные уведомления, удобный просмотр, управление списком избранного"
    },
    {
      icon: "fi fi-rr-search",
      title: "Поиск аниме по скриншоту",
      description: "Загрузите скриншот из любого аниме, и наша система мгновенно определит название и эпизод",
      accent: "Технология распознавания изображений, поиск похожих сцен и высокая точность"
    },
    {
      icon: "fi fi-rr-book",
      title: "Манга, манхва и маньхуа с AI-переводом",
      description: "Читайте мангу, корейскую манхву и китайскую маньхуа с умным переводом прямо поверх оригинального текста",
      accent: "Перевод в реальном времени, сохранение оригинального оформления, комфортное чтение сразу после выхода"
    }
  ];

  // Функция для открытия музыкального плеера
  const openMusicPlayer = () => {
    setShowMusicPlayer(true);
  };

  return (
    <div className="home">
      <Snow />
      
      {/* Добавляем музыкальный плеер */}
      {showMusicPlayer && (
        <MusicPlayer 
          isOpen={showMusicPlayer} 
          onClose={() => setShowMusicPlayer(false)} 
          trackData={selectedTrack} 
        />
      )}
      
      {/* Header */}
      <header className="header">
        <div className="logo">
          <span className="logo-text">AniNew</span>
        </div>
        <div className="auth-buttons">
          <button onClick={() => navigate('/auth')} className="auth-button">
            <i className="fi fi-rr-user"></i>
            <span>Вход/Регистрация</span>
          </button>
          <a 
            href="https://t.me/aninewser" 
            target="_blank" 
            rel="noopener noreferrer"
            className="telegram-link"
          >
            <i className="fi fi-brands-telegram"></i>
          </a>
        </div>
      </header>

      {/* Hero Section */}
      <section className="hero" ref={heroRef}>
        <div className="hero-image"></div>
        <div className="hero-gradient"></div>
        <div className="hero-content">
          <h1 className="animated-item">Погрузитесь в мир аниме</h1>
          <p className="hero-subtitle animated-item">Лучшая платформа для просмотра аниме с множеством уникальных функций</p>
          <div className="hero-buttons animated-item">
            <button 
              className="start-button"
              onClick={() => navigate('/main')}
            >
              <i className="fi fi-rr-play"></i> Начать просмотр
            </button>
            <button 
              className="features-button"
              onClick={() => scrollToSection('features')}
            >
              <i className="fi fi-rr-info"></i> Узнать больше
            </button>
          </div>
        </div>
      </section>

      {/* Features Tabs */}
      <section id="features" className="features-tabs">
        <div className="tabs-header animated-item">
          <h2 className="section-title">Почему выбирают нас</h2>
          <div className="tabs-nav">
            <button 
              className={`tab-button ${activeTab === 'benefits' ? 'active' : ''}`}
              onClick={() => setActiveTab('benefits')}
            >
              <i className="fi fi-rr-star"></i> Преимущества
            </button>
            <button 
              className={`tab-button ${activeTab === 'exclusive' ? 'active' : ''}`}
              onClick={() => setActiveTab('exclusive')}
            >
              <i className="fi fi-rr-crown"></i> Эксклюзивы
            </button>
            <button 
              className={`tab-button ${activeTab === 'upcoming' ? 'active' : ''}`}
              onClick={() => setActiveTab('upcoming')}
            >
              <i className="fi fi-rr-rocket"></i> Скоро
            </button>
          </div>
        </div>
        
        <div className="tabs-content">
          {/* Benefits Tab */}
          <div className={`tab-panel ${activeTab === 'benefits' ? 'active' : ''}`}>
            <div className="cards-grid">
              {benefitItems.map((item, index) => (
                <div key={index} className="feature-card animated-item">
                  <div className="card-icon">
                    <i className={item.icon}></i>
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <div className="card-accent">
                    <i className="fi fi-rr-bulb"></i>
                    <span>{item.accent}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          {/* Exclusive Tab */}
          <div className={`tab-panel ${activeTab === 'exclusive' ? 'active' : ''}`}>
            <div className="exclusive-content">
              <div className="exclusive-features">
                <h3 className="exclusive-title animated-item">
                  <i className="fi fi-rr-sparkles"></i> Эксклюзивные функции
                </h3>
                <div className="exclusive-cards">
                  {exclusiveItems.map((item, index) => (
                    <div key={index} className="exclusive-card animated-item">
                      <div className="exclusive-card-icon">
                        <i className={item.icon}></i>
                      </div>
                      <div className="exclusive-card-content">
                        <h4>{item.title}</h4>
                        <p>{item.description}</p>
                        {item.title === "Музыкальная коллекция" && (
                          <button 
                            className="demo-button ripple"
                            onClick={openMusicPlayer}
                          >
                            <i className="fi fi-rr-play"></i> Послушать пример
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="exclusive-demo">
                <div className="chat-demo-container">
                  <div className="chat-header">                
                    <div className="character-avatar-container">
                      <div className="avatar-shine"></div>
                      <img 
                        src="https://shikimori.one/system/characters/original/17.jpg" 
                        alt="Персонаж" 
                        className="character-avatar"
                      />
                    </div>
                    
                    <div className="character-info">
                      <h2 className="character-name">Наруто Узумаки</h2>
                      <p className="character-anime">Наруто</p>
                    </div>
                  </div>
                  
                  <div className="chat-messages custom-scrollbar">
                    <div className="message user-message">
                      Привет! Как у тебя дела?
                      <div className="message-time">
                        {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      </div>
                    </div>
                    <div className="message character-message">
                      Здравствуй! У меня всё отлично, даттебайо! Готовлюсь стать Хокаге, как и обещал! А у тебя как дела?
                      <div className="message-time">
                        {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      </div>
                    </div>
                    <div className="message user-message">
                      Расскажи мне о своём мире?
                      <div className="message-time">
                        {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      </div>
                    </div>
                    <div className="message character-message">
                      Конечно! Я живу в деревне Скрытого Листа, Коноха. Это одна из великих скрытых деревень, где живут ниндзя. Мы используем чакру для создания дзюцу — особых техник для сражений и других нужд. Моя мечта — стать Хокаге, лидером деревни!
                      <div className="message-time">
                        {new Date().toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                      </div>
                    </div>
                  </div>
                  
                  <div className="chat-input-demo">
                    <div className="input-container">
                      <input 
                        type="text" 
                        placeholder="Напишите сообщение..." 
                        disabled 
                      />
                    </div>
                    <button className="send-button ripple disabled">
                      <i className="fi fi-rr-paper-plane"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          {/* Upcoming Tab */}
          <div className={`tab-panel ${activeTab === 'upcoming' ? 'active' : ''}`}>
            <div className="upcoming-intro animated-item">
              <i className="fi fi-rr-rocket-lunch"></i>
              <h3>Скоро на платформе</h3>
              <p>Мы постоянно развиваемся и добавляем новые функции для улучшения вашего опыта</p>
            </div>
            <div className="cards-grid">
              {upcomingItems.map((item, index) => (
                <div key={index} className="upcoming-card animated-item">
                  <div className="card-icon">
                    <i className={item.icon}></i>
                  </div>
                  <div className="upcoming-badge">Скоро</div>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <div className="card-accent">
                    <span>{item.accent}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="cta-section animated-item">
        <div className="cta-content">
          <h2>Готовы начать?</h2>
          <p>Присоединяйтесь к тысячам пользователей, которые уже оценили нашу платформу</p>
          <button 
            className="cta-button"
            onClick={() => navigate('/main')}
          >
            <i className="fi fi-rr-play"></i> Смотреть аниме
          </button>
        </div>
        <div className="cta-particles">
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
          <div className="particle"></div>
        </div>
      </section>

      {/* Footer */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <span className="logo-text">AniNew</span>
            <p>Ваш путеводитель в мире аниме</p>
          </div>
          <div className="footer-links">
            <div className="footer-links-column">
              <h4>Навигация</h4>
              <ul>
                <li><a href="#top">Главная</a></li>
                <li><a href="#features">Особенности</a></li>
                <li><button onClick={() => navigate('/main')}>Каталог аниме</button></li>
                <li><button onClick={() => navigate('/auth')}>Регистрация</button></li>
              </ul>
             <h4>Поддержка</h4>
             <ul>
               <li><a href="#">Помощь</a></li>
               <li><a href="#">Часто задаваемые вопросы</a></li>
               <li><a href="#">Связаться с нами</a></li>
               <li><a href="#">Правовая информация</a></li>
             </ul>
           </div>
           <div className="footer-links-column">
             <h4>Контакты</h4>
             <ul className="social-links">
               <li>
                 <a href="https://t.me/slava00000001" target="_blank" rel="noopener noreferrer">
                   <i className="fi fi-brands-telegram"></i> Идеи, предложения и отзывы: @slava00000001
                 </a>
               </li>
               <li>
                 <a href="https://t.me/fresed1" target="_blank" rel="noopener noreferrer">
                   <i className="fi fi-brands-telegram"></i> Основной разработчик: @fresed1
                 </a>
               </li>
             </ul>
           </div>
         </div>
       </div>
       <div className="footer-bottom">
         <p>© 2025 AniNew. Все права защищены.</p>
       </div>
     </footer>

     {/* Scroll to Top Button */}
     {showScrollTop && (
       <button 
         className="scroll-top-button"
         onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
         aria-label="Наверх"
       >
         <i className="fi fi-rr-arrow-up"></i>
       </button>
     )}
   </div>
 );
};

export default HomePage;
